// const VUE_APP_PLATFORM_URL = process.env.VUE_APP_PLATFORM_URL;
// const VUE_APP_MARKET_URL = process.env.VUE_APP_MARKET_URL;
const VUE_APP_INTERNATIONAL_URL = process.env.VUE_APP_INTERNATIONAL_URL;
// const VUE_APP_PAY_URL = process.env.VUE_APP_PAY_URL;
// const VUE_APP_EXCHANGE_URL = process.env.VUE_APP_EXCHANGE_URL;

const newtk = localStorage.getItem('accessToken')
const companyReference = localStorage.getItem("companyReference")
const companyAccountId = localStorage.getItem("companyAccountId")

export function handleError(error, language) {
    switch (error.response.status) {
        // case 401:
        //     localStorage.removeItem('accessToken');
        //     window.location.href = `/${language}/account/login`
        //     break;

        // case 403:
        //     window.location.href = `/${language}/account/consent`
        //     break;

        case 409:
            if (error.response.data === "merchant_must_have_compliance") {
                window.location.href = `/${language}/merchant/company/${companyReference}`
            }

            if (error.response.data === "account_must_have_kyc") {
                window.location.href = `${VUE_APP_INTERNATIONAL_URL}${language}/account/token-auth?tk=${newtk}&destiny=account/kyc`
            }

            if (error.response.data === "account_must_have_compliance") {
                window.location.href = `${VUE_APP_INTERNATIONAL_URL}${language}/account/token-auth?tk=${newtk}&destiny=account/compliance`
            }

            if (error.response.data === "account_must_have_company_compliance") {
                window.location.href = `${VUE_APP_INTERNATIONAL_URL}${language}/account/token-auth?tk=${newtk}&destiny=account/compliance-company`
            }

            if (error.response.data === "account_already_have_wallet_stake") {
                window.location.href = `${VUE_APP_INTERNATIONAL_URL}${language}/account/token-auth?tk=${newtk}&destiny=client-space/purchase`
            }

            if (error.response.data === "account_must_be_manualy_checked") {
                window.location.href = `${VUE_APP_INTERNATIONAL_URL}${language}/account/token-auth?tk=${newtk}&destiny=account/pending`
            }

            if (error.response.data === "account_must_have_beneficial_owner") {
                window.location.href = `${VUE_APP_INTERNATIONAL_URL}${language}/account/token-auth?tk=${newtk}&destiny=account/ubo/person`
            }

            if (error.response.data === "account_must_have_company_beneficial_owner") {
                window.location.href = `${VUE_APP_INTERNATIONAL_URL}${language}/account/token-auth?tk=${newtk}&destiny=account/ubo/company?id=${companyAccountId}`
            }

            if (error.response.data === "wrong_account_type") {
                this.blockPurchase = true
            }

            if (error.response.data === "account_must_have_wallet_stake") {
                window.location.href = `${VUE_APP_INTERNATIONAL_URL}/${language}/client-space/wallet-stake`
            }
            break;

        default:
            console.log(`Sorry, we are out of ${error.response.status}.`);
    }
}