<template>
    <div class="merchant page-sales">
        <div id="dne"></div>
        <Header :dataSource="headerTemplate" />

        <!-- HEADER STORE -->
        <div class="load" v-if="loadMerchantStore"></div>
        <HeaderStore :dataSource="dataMerchantStore" v-if="!loadMerchantStore" />

        <!-- BREADCRUMB -->
        <Breadcrumb :dataSource="breadcrumb" />

        
        <!-- CONTENT -->
        <section class="content-sales content-sales-details">
            <div class="container">
                <div class="load" v-if="load"></div>

                <div class="row" v-if="errorsGet.dataSaleDetails != false">
                    <div class="col-12">
                        <div class="box box-error">
                            <h3 class="title-secondary" style="color: #FFF">
                                {{getMyLanguage("box-error","title-error")}}
                            </h3>

                            <p v-if='errorsGet.dataSaleDetails != "401"'>
                                {{getMyLanguage("box-error","msg-error")}}
                            </p>

                            <p v-if='errorsGet.dataSaleDetails == "401"'>
                                {{getMyLanguage("box-error","401")}}
                            </p>
                        </div>
                    </div>
                </div>

                <div class="cont-sales" data-aos="fade-up" data-aos-offset="-100" v-if="!load && !errorsGet.dataSaleDetails">
                    <h2 class="title-secondary subtitle">
                        <!-- <small>{{storeName}}</small> -->
                        {{getMyLanguage("Merchant","sales.title-details")}}
                    </h2>

                    <!-- STATUS PURCHASE -->
                    <div class="status-purchase">
                        <div class="content">
                            <h3>
                                Status da venda
                                <strong>{{ getMyLanguage("Account","purchases.status." + dataSaleDetails.status_extra) }}</strong>
                            </h3>

                            <div class="btn-primary" @click="changeNewStatus()">Atualizar status do pedido</div>
                        </div>
                    </div>

                    <div class="sales">
                        <div class="sale" id="purchaseDetails">
                            <div class="header-print">
                                <div class="logo-company">
                                    <div class="logo-dne-market"></div>
                                    <div class="partner">
                                        <h4><strong>{{dataSaleDetails.store.name}}</strong></h4>
                                        <p>+351 222 222 222</p>
                                    </div>
                                </div>

                                <div class="content">
                                    <div class="buyer">
                                        <div class="name">
                                            <p><small>{{getMyLanguage("Account","purchases.purchase.buyers-name")}}</small></p>
                                            <p><strong>{{dataSaleDetails.customer.full_name}}</strong></p>
                                        </div>

                                        <div class="email">
                                            <p><small>{{getMyLanguage("Account","purchases.purchase.buyers-email")}}</small></p>
                                            <p><strong>{{dataSaleDetails.customer.email}}</strong></p>
                                        </div>

                                        <div class="phone-number">
                                            <p><small>{{getMyLanguage("Account","purchases.purchase.buyers-phone-number")}}</small></p>
                                            <p><strong>{{dataSaleDetails.customer.phone_number}}</strong></p>
                                        </div>
                                    </div>

                                    <div class="shipping">
                                        <div class="address">
                                            <p><small>{{getMyLanguage("Account","purchases.purchase.buyers-shipping-address")}}</small></p>
                                            
                                            <p>
                                                <strong>
                                                    {{dataSaleDetails.customer.address.address1}}, {{dataSaleDetails.customer.address.number}}
                                                    <span v-if="dataSaleDetails.customer.address.address2">, {{dataSaleDetails.customer.address.address2}}</span>, {{dataSaleDetails.customer.address.postal_code}} | {{dataSaleDetails.customer.address.city}} | {{dataSaleDetails.customer.address.state}} | {{dataSaleDetails.customer.address.country}}
                                                </strong>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="order">
                                <div class="resume-sale">
                                    <div class="header-sale">
                                        <div class="buyer">
                                            <div>
                                                <small>{{getMyLanguage("Account","purchases.purchase.buyers-name")}}</small>
                                                <strong>{{dataSaleDetails.customer.full_name}}</strong>
                                            </div>

                                            <div>
                                                <small>{{getMyLanguage("Account","purchases.purchase.buyers-email")}}</small>
                                                
                                                <a :href="`mailto:${dataSaleDetails.customer.email}`">
                                                    <strong>{{dataSaleDetails.customer.email}}</strong>
                                                </a>
                                            </div>
                                            
                                            <div>
                                                <small>{{getMyLanguage("Account","purchases.purchase.buyers-phone-number")}}</small>
                                                
                                                <a :href="`tel:${dataSaleDetails.customer.phone_number}`">
                                                    <strong>{{dataSaleDetails.customer.phone_number}}</strong>
                                                </a>
                                            </div>
                                        </div>

                                        <div class="date-status">
                                            <div>
                                                <small>{{getMyLanguage("Account","purchases.purchase.reference")}}</small>
                                                <strong>{{dataSaleDetails.reference}}</strong>
                                            </div>

                                            <div class="date">
                                                <small>{{getMyLanguage("Account","purchases.purchase.purchase-date")}}</small>
                                                <strong>{{dateTime(dataSaleDetails.created_at)}}</strong>
                                            </div>
                                            
                                            <div class="status" :class="dataSaleDetails.status">
                                                <small>{{getMyLanguage("Account","purchases.purchase.status")}}</small>
                                                <strong>{{ getMyLanguage("Account","purchases.status." + dataSaleDetails.status_extra) }}</strong>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="products">
                                        <a :href="`/${item.product.slug}`" class="items" target="_blank"
                                        v-for="(item, indexItem) in dataSaleDetails.items"
                                        :key="indexItem">

                                            <div class="item">
                                                <div class="photo">
                                                    <img :src="item.product.attachment">
                                                </div>

                                                <div class="details">
                                                    <p class="name">{{item.product.name}}</p>

                                                    <div class="price">
                                                        <span>
                                                            {{item.price.toLocaleString("pt-PT", {minimumFractionDigits: "2", maximumFractionDigits: "4"})}}
                                                            <i></i>
                                                        </span>

                                                        <small v-if="(item.quantity = 1)">
                                                            ({{item.quantity}} {{getMyLanguage("Account","purchases.purchase.item")}})
                                                        </small>

                                                        <small v-if="(item.quantity > 1)">
                                                            ({{item.quantity}} {{getMyLanguage("Account","purchases.purchase.items")}})
                                                        </small>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="shipping-carrier-details">
                                                <div class="status">
                                                    <span>Enviado com sucesso</span>
                                                    <span>Enviado</span>
                                                </div>

                                                <div class="carrier">
                                                    <span>Transportadora: <strong>CTT</strong></span>
                                                    <span>Rastreamento: <strong>https://ctt.pt</strong></span>
                                                    <span>Código: <strong>BR44655664PT</strong></span>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>

                                    
                                <div class="box-summary">
                                    <div class="resume-purchase">
                                        <div>
                                            <p><small>{{getMyLanguage("Account","purchases.purchase.reference")}}</small></p>
                                            <p><strong>{{dataSaleDetails.reference}}</strong></p>
                                        </div>

                                        <div>
                                            <p><small>{{getMyLanguage("Account","purchases.purchase.purchase-date")}}</small></p>
                                            <p><strong>{{dateTime(dataSaleDetails.created_at)}}</strong></p>
                                        </div>
                                        
                                        <div>
                                            <p><small>{{getMyLanguage("Account","purchases.purchase.purchase-delivery-date")}}</small></p>
                                            <p><strong>{{dateTime(dataSaleDetails.created_at)}}</strong></p>
                                        </div>
                                    </div>

                                    <table>
                                        <tr>
                                            <td><strong>{{getMyLanguage("Account","purchases.summary.products")}}</strong></td>
                                            
                                            <td>
                                                <span>
                                                    {{dataSaleDetails.summary.items_price.toLocaleString("pt-PT", {minimumFractionDigits: "2", maximumFractionDigits: "4"})}}
                                                </span>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td><strong>{{getMyLanguage("Account","purchases.summary.discount")}}</strong></td>
                                            
                                            <td>
                                                <span>
                                                    {{dataSaleDetails.summary.discount.toLocaleString("pt-PT", {minimumFractionDigits: "2", maximumFractionDigits: "4"})}}
                                                </span>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td><strong>{{getMyLanguage("Account","purchases.summary.subtotal")}}</strong></td>
                                            
                                            <td>
                                                <span>
                                                    {{dataSaleDetails.summary.subtotal.toLocaleString("pt-PT", {minimumFractionDigits: "2", maximumFractionDigits: "4"})}}
                                                </span>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td><strong>{{getMyLanguage("Account","purchases.summary.shipping")}}</strong></td>
                                            
                                            <td>
                                                <span>
                                                    {{dataSaleDetails.summary.shipping.toLocaleString("pt-PT", {minimumFractionDigits: "2", maximumFractionDigits: "4"})}}
                                                </span>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td><strong>{{getMyLanguage("Account","purchases.summary.tax")}}</strong></td>
                                            
                                            <td>
                                                <span>
                                                    {{dataSaleDetails.summary.tax.toLocaleString("pt-PT", {minimumFractionDigits: "2", maximumFractionDigits: "4"})}}
                                                </span>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td><strong>{{getMyLanguage("Account","purchases.summary.fee")}}</strong></td>
                                            
                                            <td>
                                                <span>
                                                    {{dataSaleDetails.summary.fee.toLocaleString("pt-PT", {minimumFractionDigits: "2", maximumFractionDigits: "4"})}}
                                                </span>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td><strong>{{getMyLanguage("Account","purchases.summary.total")}}</strong></td>
                                            
                                            <td>
                                                <span>
                                                    {{dataSaleDetails.summary.total_amount.toLocaleString("pt-PT", {minimumFractionDigits: "2", maximumFractionDigits: "4"})}}
                                                </span>
                                            </td>
                                        </tr>

                                        <tr class="cashback">
                                            <td><strong>{{getMyLanguage("Account","purchases.summary.cashback")}}</strong></td>
                                            
                                            <td>
                                                <span>
                                                    {{dataSaleDetails.summary.cashback.toLocaleString("pt-PT", {minimumFractionDigits: "2", maximumFractionDigits: "4"})}}
                                                </span>
                                            </td>
                                        </tr>
                                    </table>

                                    <div class="bts">
                                        <!-- <div class="download-pdf">
                                            <div @click="pdfPurchaseSingle(purchase.reference, indexPurchase)" class="btn-primary" v-html='getMyLanguage("Btn","download-pdf")'></div>
                                        </div> -->

                                        <div class="download-pdf"
                                        @click="pdfPurchaseSingle(dataSaleDetails.reference)"
                                        v-html='getMyLanguage("Btn","download-pdf")'></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <!-- MAP -->
                    <div class="map-buyer">
                        <h2 class="title-secondary">
                            Endereço de entrega
                        </h2>

                        <div class="address">
                            <p>
                                {{dataSaleDetails.customer.address.address1}},&nbsp;
                                {{dataSaleDetails.customer.address.number}}
                                <span v-if="dataSaleDetails.customer.address.address2">
                                    ,&nbsp;{{dataSaleDetails.customer.address.address2}}
                                </span>
                                ,&nbsp;{{dataSaleDetails.customer.address.postal_code}}
                                &nbsp;|&nbsp;{{dataSaleDetails.customer.address.city}}
                                &nbsp;|&nbsp;{{dataSaleDetails.customer.address.state}}
                                &nbsp;|&nbsp;{{dataSaleDetails.customer.address.country}}
                            </p>
                        </div>

                        <iframe :src="`https://maps.google.com/maps?q=${dataSaleDetails.customer.address.lat_long}&hl=es;z=14&amp;output=embed`" frameborder="0"></iframe>
                    </div>
                </div>
            </div>
        </section>
    </div>


    <!-- MODAL STATUS PURCHASE -->
    <div class="modal modal-status-purchase" v-if="modalStatusPurchase.show">
        <div class="modal-cont">
            <div class="modal-close" @click="modalStatusPurchase.show = false"></div>

            <div class="modal-scroll">
                <h2 class="title-secondary">Status</h2>
                
                <div class="status">
                    <div class="content">
                        <span>O status atual deste pedido:</span>
                        
                        <strong :class="dataSaleDetails.status_extra">
                            {{ getMyLanguage("Account","purchases.status." + dataSaleDetails.status_extra) }}
                        </strong>
                    </div>
                </div>

                <div class="new-status">
                    <label>
                        <strong>Novo status</strong>
                        <select class="form" v-model="modalStatusPurchase.newStatus">
                            <option value="" disabled selected>Selecione um novo status</option>
                            
                            <option
                                :value="status.Extra"
                                v-for="(status, index) in dataStatusPurchase"
                                :key="index">

                                    {{ getMyLanguage("Account","purchases.status." + status.Extra) }}
                            </option>

                            <!-- NO POST, FAZER FILTER PARA ENVIAR O ID DO STATUS -->
                        </select>
                    </label>

                    <!-- <pre>{{dataStatusPurchase}}</pre> -->


                    <!-- SHIPPED -->
                    <div class="box-content" v-if="modalStatusPurchase.newStatus == 'shipped' || modalStatusPurchase.newStatus == 'partially_shipped'">
                        <label>
                            <strong>Selecione a transportadora</strong>
                            <select class="form" v-model="modalStatusPurchase.content.shipping_provider_id">
                                <option value="8d7bc7fa-1784-4e02-85a6-2c207710d148" selected>CTT Portugal</option>
                            </select>
                        </label>

                        <label>
                            <strong>Link para acompanhar a entrega</strong>
                            <input type="text" class="form" v-model="modalStatusPurchase.content.tracking_number">
                        </label>
                    </div>


                    <!-- CANCELLED /  -->
                    <div class="box-content" v-if="modalStatusPurchase.newStatus == 'cancelled'">
                        <label>
                            <strong>Descreva brevemente o motivo</strong>
                            <textarea class="form" maxlength="50" v-model="modalStatusPurchase.content.status_reason"></textarea>
                        </label>
                    </div>


                    <div class="check-update">
                        <div class="form-group-check">
                            <input type="checkbox" class="form-check" id="checkUpdate" v-model="modalStatusPurchase.chedk_update">
                            
                            <label for="checkUpdate" class="form-check-label">
                                * Confirmo a alteração do status deste pedido
                            </label>
                        </div>

                        <div class="btn-primary btn-disabled"
                            v-if="!modalStatusPurchase.chedk_update && !modalStatusPurchase.load">

                            Atualizar status
                        </div>

                        <div class="btn-primary"
                            v-if="modalStatusPurchase.chedk_update && !modalStatusPurchase.load"
                            @click="updateStatus()">
                            
                            Atualizar status
                        </div>

                        <div class="load" v-if="modalStatusPurchase.load"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>


</template>

<script>
    // const $ = require('jquery')
    // window.$ = $

    import Header from "@/components/Essentials/Header.vue";
    import HeaderStore from "@/components/Merchant/HeaderStore.vue";
    import Breadcrumb from "@/components/Merchant/Breadcrumb.vue";

    import getMyContentLanguage from "@/services/contentLanguage.js";

    import apiMerchant from '@/config/apiMerchant.js'

    import bus from '@/eventBus';

    import moment from 'moment';

    import { handleError } from '@/services/errorHandler.js'

    const html2pdf = require('html2pdf.js')

    export default {
        components: {
            Header,
            HeaderStore,
            Breadcrumb
        },

        data() {
            return {
                flagSelected: "en",
                headerTemplate: "merchant",
                breadcrumb: "",
                load: true,
                dataSaleDetails: {},
                dataStatusPurchase: [],

                dataMerchantStore: {},
                loadMerchantStore: true,

                modalStatusPurchase: {
                    show: false,
                    newStatus: "",
                    content: {
                        shipping_provider_id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
                        status_reason: "",
                        tracking_number: ""
                    },
                    chedk_update: false
                },

                storeName: "",
                orderId: "",

                errorsGet: {
                    dataSaleDetails: false
                }
            }
        },

        async mounted() {
            this.languageSelected()

            // HEADER STORE
            await apiMerchant('/api/v1/market/store')
            .then(response => {
                setTimeout(() => {
                    this.dataMerchantStore = response.data.store
                    this.loadMerchantStore = false
                }, 500);
            })
            .catch(error => {
                setTimeout(() => {
                    this.errorsGet.dataMerchantStore = error.response.status
                    this.loadMerchantStore = false
                    localStorage.removeItem("storeReference")
                }, 500);
            })

            this.storeName = localStorage.getItem("storeName")

            this.orderId = this.$route.params.orderId

            await apiMerchant.get(`/api/v1/market/order/${this.orderId}`)
            .then(response => {
                this.dataSaleDetails = response.data

                this.loadStatusPurchase(this.dataSaleDetails.status_extra)
            })
            .catch(error => {
                this.load = false
                this.errorsGet.dataSaleDetails = JSON.stringify(error.response.status)

                if ( error.response.status == 401 ){
                    bus.emit('open-login',"401");
                }

                handleError(error, this.flagSelected, this.$store.state.profile.account_type);
                
                setTimeout(() => {
                    this.load = false
                }, 500);
            })

            this.breadcrumb = [
                {
                    slug: `${this.flagSelected}/merchant/dashboard`,
                    name: this.getMyLanguage("Merchant","breadcrumb.dashboard")
                },
                {
                    slug: `${this.flagSelected}/merchant/sales`,
                    name: this.getMyLanguage("Merchant","breadcrumb.sales")
                },
                {
                    name: this.getMyLanguage("Merchant","breadcrumb.sale-details")
                }
            ]
        },

        methods: {
            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code
            },

            getMyLanguage(group, code) {
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code);
            },

            dateTime(value) {
                const format = this.flagSelected === 'br' ? 'pt' : this.flagSelected;
                return moment.utc(value).locale(format).format('ll');
            },

            loadStatusPurchase(status){
                apiMerchant.get(`/api/v2/market/order/status/${status}`)
                .then(response => {
                    this.dataStatusPurchase = response.data

                    setTimeout(() => {
                        this.load = false
                    }, 500);
                })
                .catch(error => {
                    console.log(error)
                    this.$toast.error("Houve um erro ao carregar o status do pedido.")

                    setTimeout(() => {
                        this.load = false
                    }, 500);
                })
            },

            changeNewStatus(){
                this.modalStatusPurchase.newStatus = ""
                this.modalStatusPurchase.content.shipping_provider_id = "3fa85f64-5717-4562-b3fc-2c963f66afa6"
                this.modalStatusPurchase.content.status_reason = ""
                this.modalStatusPurchase.content.tracking_number = ""
                this.modalStatusPurchase.chedk_update = false
                this.modalStatusPurchase.show = true
            },

            updateStatus(){
                this.modalStatusPurchase.load = true

                // apiMerchant.post(`/api/v2/market/order/${this.dataSaleDetails.Id}/item/${this.dataSaleDetails.items[0].Id}/status/${this.modalStatusPurchase.newStatus}`,this.modalStatusPurchase.content)
                apiMerchant.post(`/api/v2/market/order/${this.dataSaleDetails.Id}/status/${this.modalStatusPurchase.newStatus}`,this.modalStatusPurchase.content)
                .then(response => {
                    console.log(response.data)
                    console.clear()
                    
                    location.reload()
                })
                .catch(error => {
                    console.log(error)
                    this.modalStatusPurchase.load = false
                    this.$toast.error("Houve um erro ao atualizar o status")
                })

                // setTimeout(() => {
                //     this.modalStatusPurchase.show = false
                //     this.modalStatusPurchase.load = false
                    
                //     this.$toast.success("Status atualizado com sucesso!")
                // }, 1500);
            },

            pdfPurchaseSingle(value) {
                html2pdf(document.getElementById("purchaseDetails"), {
                    filename: `Receipt ${value} DNE Market`,
                    margin: 15
                });
            }
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected();
                }
            }
        },

        created() {
            const meta = document.querySelector('meta[name="robots"]')
            if (meta) {
                meta.content = 'noindex,nofollow'
            } else {
                const newMeta = document.createElement('meta')
                newMeta.name = 'robots'
                newMeta.content = 'noindex,nofollow'
                document.getElementsByTagName('head')[0].appendChild(newMeta)
            }
        },
    };
</script>

<style src="./custom-sales.scss" lang="scss" scoped />
<style src="./custom-sales-details.scss" lang="scss" scoped />

<style lang="scss" scoped>
    @import '@/assets/scss/_colors.scss';
    @import '@/assets/scss/_fonts.scss';
    @import '@/assets/scss/_dneElements.scss';

    .header-print{
        // margin-bottom: 20px;

        .logo-company{
            display: flex;
            justify-content: space-between;
            margin-bottom: 10px;

            .logo-dne-market{
                width: 30%;
                height: 70px;
                background: url('~@/assets/images/header/logo-dne-market.svg') no-repeat left center;
                background-size: auto 100%;
            }

            .partner{
                h4{
                    font-size: 20px;
                    line-height: 20px;
                    margin-bottom: 2px;
                }

                p{
                    margin-bottom: 0;
                    font-size: 12px;
                    line-height: 18px;
                }
            }
        }
        
        .content{    
            border: 1px solid #ccc;
            padding: 5px 15px 22px 15px;

            small{
                display: block;
                line-height: 12px;
                margin-bottom: 5px;
            }
            
            p{
                margin-bottom: 0;
                font-size: 12px;
                line-height: 18px;
            }

            .buyer{
                display: flex;
                width: 100%;
                margin-bottom: 20px;

                .email, .phone-number{
                    margin-left: 20px;
                }
            }

            .shipping-carrier-details{
                .status, .carrier{
                    display: flex;
                    flex-wrap: wrap;

                    span{
                        display: flex;
                        white-space: nowrap;
            
                        &:not(&:first-child){
                            margin-left: 5px;
                        }
            
                        font-size: 13px;
                        line-height: 13px;
                        
                        strong{
                            padding-left: 5px;
                        }
                    }
                }
            }
        }
    }

    .header-sale{
        display: none;
    }


    .products{
        margin-top: 40px;
        border: 1px solid #ccc;
        padding: 0 15px 22px 15px;

        .items{
            &:not(&:last-child){
                .item{
                    padding-bottom: 20px;
                    border-bottom: 1px solid #ccc;
                }
            }

            .item{
                position: relative;
                z-index: 5;
                display: flex;

    
                .photo{
                    display: none;
                }
    
                .details{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    width: 100%;
    
                    .name{
                        color: $color-text-primary;
                        font-family: $font-primary-semibold;
                        font-size: 13px;
                        margin-bottom: 3px;
                    }
    
                    .price{
                        display: flex;
                        width: 100%;
    
                        span{
                            display: block;
                            font-family: $font-primary-semibold;
                            color: $color-text-primary;
                            align-items: center;
                            height: 20px;
                            border-right: none;
                            font-size: 13px;
                            line-height: 20px;
                            margin-right: 15px;
                            padding-right: 21px;
                            background: url('~@/assets/images/icons/dne-symbol-pdf.png') no-repeat right right;
                            background-size: auto 12px;
                        }
    
                        small{
                            font-size: 12px;
                            line-height: 20px;
                        }
                    }
                }
            }
        }
    }

    .box-summary{
        display: flex;
        justify-content: space-between;

        .resume-purchase{
            display: block;
            width: calc(50% - 10px);
            padding: 0 16px 6px;
            background: $color-text-tertiary;
            border: 1px solid #ccc;
            margin-top: 40px;

            div{
                margin-bottom: 15px;;

                p{
                    font-size: 16px;
                    margin: 0;

                    strong{
                        color: $color-primary;
                    }
                }
            }
        }

        table{
            width: calc(50% - 10px);
            padding: 0 16px 6px;
            background: $color-text-tertiary;
            border: 1px solid #ccc;
            margin-top: 40px;
            
            tr{
                &.cashback{
                    td{
                        border-bottom: none;
                    }
                }
    
                td{
                    border-bottom: 1px solid #ddd;
                    padding: 0;
    
                    &:nth-of-type(2){
                        text-align: right;
                    }
    
                    strong{
                        position: relative;
                        top: -5px;
                        display: block;
                        font-family: $font-primary-semibold;
                        font-size: 12px;
                        line-height: 12px;
                        height: 22px;
                        color: $color-text-primary;
                    }
                    
                    span{
                        position: relative;
                        top: -5px;
                        display: inline-flex;
                        font-family: $font-primary-semibold;
                        color: $color-text-primary;
                        font-size: 12px;
                        line-height: 12px;
                        height: 22px;
                        padding-right: 21px;
                        background: url('~@/assets/images/icons/dne-symbol-pdf.png') no-repeat right 10px;
                        background-size: auto 12px;
                    }
                }
            }
        }

        .bts{
            display: none;
        }
    }


    // DETAILS
    .map-buyer{
        display: none;
    }
</style>

<style>
    .download-pdf .btn-primary small{display: block; margin-left: 10px; font-size: 12px;}
</style>