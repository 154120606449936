// import apiMerchant from '@/config/apiMerchant.js'
import apiPlatform from '@/config/apiPlatform.js'

import moment from 'moment';

export default {
    data(){
        return {
            load: false,

            flowSave: {
                load: false,
                step: "",
                modalConfirm: false,
                confirmAccepted: false,
            },

            dataMerchant: [],
            dataSave: {},

            newCompany: false,

            storeDetails: {
                address: ""
            },

            errorsPostSave: {
                dataMerchant: false,
                partner: false,
            },
        }
    },

    methods: {
        // SAVE STEP
        saveStep(value){
            this.flowSave.load = true
            this.flowSave.step = value

            if ( this.flowSave.step == "stp01" ){
                apiPlatform.post(`/api/v1/international/company/${this.dataMerchant.reference}`, this.dataMerchant)
                .then(response => {
                    this.dataMerchant = response.data
                    this.$toast.success(this.getMyLanguage("Store","merchant.store.toast.updated-successfully"))

                    if ( this.$route.params.companyReference == "0" ){
                        setTimeout(() => {
                            localStorage.setItem("companyReference", response.data.reference)
                            localStorage.setItem("storeReference", "0")
                            window.location.href = `/${this.flagSelected}/merchant/company/${response.data.reference}?guide=partners`
                        }, 500);
                    } else {
                        setTimeout(() => {
                            this.goTop()
                            this.flowStep.load = false
                            this.flowValidation.load = false
                            this.flowStep.statusStep01 = true
                            this.navStep("stp02")
                            // this.flowStep.stp = "step02"
                        }, 500);
                    }
                })
                .catch(error => {
                    this.$toast.warning(this.getMyLanguage("Store","merchant.store.toast.error-updating"))
                    this.errorsPostSave = error.data
                    this.flowValidation.load = false
                    console.log(error)
                    
                    if ( error.response.data == "account_activate_company_email_already_exists" ){
                        this.errorForm.company.emailPhoneExists = true
                    }

                    setTimeout(() => {
                        this.scrollToFirstError();
                    }, 300);
                })
            }

            if ( this.flowSave.step == "stp02" ){
                
                this.flowStep.load = true

                // VALIDAÇÃO SE HÁ AO MENOS 1 PARTNER

                setTimeout(() => {
                    this.$toast.success(this.getMyLanguage("Store","merchant.store.toast.updated-successfully"))
                    this.flowStep.load = false
                    this.navStep("stp03")
                    // this.flowStep.stp = "step03"
                    // this.flowStep.statusStep02 = true
                }, 500);
            }
            
            // if ( this.flowSave.step == "stp02" ){
            //     this.dataSave = this.dataMerchant.merchant
            //     console.log(this.dataSave)
                
            //     apiMerchant.post("/api/v1/platform/account/merchant", this.dataSave)
            //     .then(response => {
            //         this.dataMerchant.merchant = response.data
            //         console.log(this.dataMerchant.merchant)
            //         this.goTop()

            //         setTimeout(() => {
            //             this.$toast.success(this.getMyLanguage("Store","merchant.store.toast.updated-successfully"))
            //             this.flowStep.load = false
            //             this.flowStep.stp = "step03"
            //             this.flowStep.statusStep02 = true
            //         }, 500);
            //     })
            //     .catch(error => {
            //         this.$toast.error("Houve um erro na atualização dos dados")
            //         console.log(error)
            //         this.errorsPostSave = error.data
            //     })
            // }
            
            // if ( this.flowSave.step == "stp02" ){
            //     this.dataSave = this.dataMerchant.merchant
            //     this.flowSave.type = "merchant"
            //     this.save()
            // }
            
            if ( this.flowSave.step == "stp03" ){
                // *** verificar documentos
            }
            
            // if ( this.flowSave.step == "address" ){
            //     this.flowSave.type = "address"
            //     this.save()
            // }
        },


        // saveStep(){
        //     this.load = true
        //     const register = this.dataMerchant;

        //     let dataPhoneCompany = this.validatePhone.company.val
        //     let dataPhonePerson = this.validatePhone.person.val

        //     this.dataMerchantProfile.phoneNumber = dataPhoneCompany.replace(/[^0-9]/g,'')
        //     this.dataMerchant.phoneNumber = dataPhonePerson.replace(/[^0-9]/g,'')
            

        //     apiMarket.post("/api/v1/platform/account/register/", register)
        //     .then(response => {
        //         this.registerId = response.data.id;
        //         const languageRedirect = String(window.location.pathname).split("/")[1];
        //         window.location.href = `/${languageRedirect}/account/confirm-email-invite/${this.$route.params.registerId}`
        //     })
        //     .catch(error => {
        //         console.log(error.response.data)

        //         if ( error.response.data === "account_activate_email_already_exists" || error.response.data === "account_activate_phone_already_exists" ) {
        //             this.errorsPostSave.dataMerchant = error.response.data
        //         } else if ( error.response.data === "not_allowed_restricted_country_pep" ) {
        //             this.errorsPostSave.country_pep = true
        //         } else{
        //             this.errorsPostSave.general = error.response.status

        //             setTimeout(() => {
        //                 this.errorsPostSave.general = false
        //             }, 4000);
        //         }

        //         console.log(this.errorsPostSave)

        //         setTimeout(() => {
        //             this.load = false
        //         }, 500);
        //     })
        // },



        // REMOVE COMPANY
        removeCompany(){
            localStorage.removeItem('storeReference')
            window.location.href = `/${this.flagSelected}/merchant/dashboard`

            // apiPlatform.delete(`/api/v1/international/company/${this.dataMerchant.reference}`)
            // .then(response => {
            //     console.log(response)
            //     window.location.href = `/${this.flagSelected}/merchant/dashboard`
            // })
            // .catch(error => {
            //     console.log(error.response.data)
            // })
        },

        updateDob(date) {
            this.modalPartner.partner.dob = moment(date, 'YYYY-MM-DD').toISOString();
        },

        // SAVE PARTNER
        savePartner(){
            this.flowStep.load = false
            this.updateDob(this.modalPartner.partner.dob)
                
            apiPlatform.post(`/api/v1/international/company/${this.dataMerchant.reference}/members/${this.modalPartner.partner.reference}`, this.modalPartner.partner)
            .then(response => {
                this.dataMerchant.members = response.data

                setTimeout(() => {
                    this.flowValidation.load = false
                    this.modalPartner.modal = false
                    this.$toast.success(this.getMyLanguage("Store","merchant.store.toast.updated-successfully"))
                }, 500);
            })
            .catch(error => {
                setTimeout(() => {
                    console.log(error.response.data)
                    this.flowValidation.load = false
                    this.errorsPostSave.partner = error.response.data
                    this.$toast.error(this.getMyLanguage("Store","merchant.store.toast.error-updating"))

                    if ( error.response.data == "account_activate_email_already_exists" ){
                        this.errorForm.partner.emailPhoneExists = true
                    }

                    // if ( error.response.data == "account_activate_email_already_exists" ){
                    //     this.errorForm.partner.emailExists = true
                    // }
                    
                    // if ( error.response.data == "account_activate_phone_already_exists" ){
                    //     this.errorForm.partner.phoneExists = true
                    // }
                }, 500);
            })
        },


        // REMOVE PARTNER
        removePartner(value){
            if ( value == "start" ){
                this.modalPartner.load = true
                this.modalPartner.type = "del"
    
                setTimeout(() => {
                    this.modalPartner.load = false
                }, 500);
            }
            else if ( value == "confirm" ){
                this.modalPartner.load = true

                apiPlatform.delete(`/api/v1/international/company/${this.dataMerchant.reference}/members/${this.modalPartner.partner.reference}`)
                .then(response => {
                    this.dataMerchant.members = response.data

                    setTimeout(() => {
                        this.modalPartner.modal = false
                        this.modalPartner.load = false
                        this.$toast.success(this.getMyLanguage("Store","merchant.store.partners.removed-partner.toast"))
                    }, 500);
                })
                .catch(error => {
                    setTimeout(() => {
                        console.log(error.response.data)
                        this.modalPartner.load = false
                        this.errorsPostSave.partner = error.response.data
                        this.$toast.error(this.getMyLanguage("box-error","msg-error"))
                    }, 500);
                })
            }
        },




        // ADD ADDRESS
        // addAddress(type){
        //     this.flowAddressAdd.modal = true
        //     this.flowAddressAdd.status = "new"
        //     this.flowAddressAdd.address.address_type_code = type
        //     this.flowAddressAdd.address.name = `${type} Address`
        // },

        // saveAddress(){
        //     this.flowAddressAdd.load = true

        //     apiMerchant.post('/api/v1/platform/account/address', this.flowAddressAdd.address)
        //     .then(response => {
        //         console.log(response.data)
        //         this.dataMerchant.store.addresses = response.data
        //         this.$toast.success("Endereço adicionado com sucesso")

        //         // checked no novo endereço...

        //         setTimeout(() => {
        //             this.flowAddressAdd.load = false
        //             this.flowAddressAdd.modal = false
        //         }, 500);
        //     })
        //     .catch(error => {
        //         console.log(error)

        //         setTimeout(() => {
        //             this.flowAddressAdd.status = "error"
        //             this.flowAddressAdd.load = false
        //         }, 500);
        //     })
        // },
    }
}