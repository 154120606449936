import apiMerchant from '@/config/apiMerchant.js'
import apiPay from '@/config/apiPay.js'
import AddVariant from '@/components/Merchant/Products/AddVariant'

export default{
    components: {
        AddVariant
    },

    data(){
        return {
            dataVariants: [],
            dataMeasureUnit: [],

            flowAddVariant: {
                modal: false,
                load: false,
                variables: {
                    type_1: "",
                    type_2: ""
                },
                cloneId: ""
            },

            flowRegisterVariant: {
                attributes: {
                    Key: '',
                    Value: ''
                },

                validate: {
                    status_id: undefined,
                    category_id: undefined,
                    brand_id: undefined,
                    name: undefined,
                    slug: undefined,
                    short_description: undefined,
                    description: undefined,
                    asset_id: undefined,
                    price: undefined,
                    quantity_available: undefined,
                    quantity_minimum: undefined,
                    quantity_maximum: undefined,
                    warehouse_id: undefined
                },

                validateShipping: {
                    weight: undefined,
                    height: undefined,
                    width: undefined,
                    length: undefined,
                    cubing: undefined,
                    shipping_measure_unit_id: undefined
                }
            },

            modalVariant: {
                modal: false,
                load: false,
                currency: [],
                modalVariant: [],
                warehouses: [],
                data: {
                    attributes: [],
                    price: "",
                    shipping: {
                        product_variant_shipping_id: "",
                        shippable: true,
                        weight: 0,
                        height: 0,
                        width: 0,
                        length: 0,
                        cubing: 0,
                        shipping_packaging_id: "",
                        shipping_measure_unit_id: ""
                    },
                    semantics: []
                }
            },


            // SHIPPING
            unit: "cm",
            weightUnit: "kg",
            volumeUnit: "m³",
            cubage: null,

            // PACKAGES
            dataPackages: [
                {
                    id: "00000000-0000-0000-0000-000000000000",
                    name: "Nenhuma (inserir valor manualmente)",
                    length: 0,
                    width: 0,
                    height: 0
                },
                {
                    id: "11111111-0000-0000-0000-000000000000",
                    name: "Small box (20 x 20 x 10)",
                    length: 20,
                    width: 20,
                    height: 10
                },
                {
                    id: "22222222-0000-0000-0000-000000000000",
                    name: "Medium box (30 x 30 x 20)",
                    length: 30,
                    width: 30,
                    height: 20
                },
                {
                    id: "33333333-0000-0000-0000-000000000000",
                    name: "Big box (40 x 40 x 30)",
                    length: 40,
                    width: 40,
                    height: 30
                }
            ]
        }
    },

    async mounted(){
        if ( this.$route.params.productID != undefined ){

            // BLOCK VARIANTS
            
            
            // LOAD VARIANTS
            await apiMerchant.get(`/api/v2/market/product/${this.$route.params.productID}/variants`)
            .then(response => {
                this.dataVariants = response.data
                
                // this.flowAddVariant.variables.type_1 = this.dataProduct.variant_type_1.id
                // this.flowAddVariant.variables.type_2 = this.dataProduct.variant_type_2.id

                // this.flowAddVariant.variables.type_1 = this.dataProduct.variant_type_id_1
                // this.flowAddVariant.variables.type_2 = this.dataProduct.variant_type_id_2
                
                // const disttinticType01 = this.getDistinctTypesVariants(this.dataVariants, 'variant_type_id_1');
                // const disttinticType02 = this.getDistinctTypesVariants(this.dataVariants, 'variant_type_id_2');

                // this.flowAddVariant.variables.type_1 = disttinticType01[1]
                // this.flowAddVariant.variables.type_2 = disttinticType02[1]

                // console.log(this.flowAddVariant.variables)
            })
            .catch(error => {
                console.log(error)
                this.$toast.error("Houve um erro ao carregar as variantes")
            })


            // LOAD STATUS
            const statusVariant = await apiMerchant.get('api/v2/public/attributes/codes/product_variant_status')
            this.modalVariant.status = statusVariant.data


            // LOAD ASSETS
            const assetFiat = await apiPay.get("api/v1/pay/asset/type/fiat")
            this.modalVariant.currency = assetFiat.data

            // setTimeout(() => {
            //     this.modalVariant.data.asset_id = this.modalVariant.currency.filter(asset => asset.short == localStorage.getItem('instrumentTickerShort'))[0].assetTypeId
            // }, 1500);


            // LOAD MEASURE UNIT
            const measureUnit = await apiMerchant.get("/api/v2/public/attributes/codes/measure_unit")
            this.dataMeasureUnit = measureUnit.data
        }
    },

    methods: {
        
        // ADD VARIABLE
        // getDistinctTypesVariants(array, column) {
        //     return [...new Set(array.map(item => item[column]))];
        // },

        addedArrayVariant(item) {
            this.flowAddVariant.load = true

            this.dataVariants.push(item)
            this.openModalVariant(item)

            setTimeout(() => {
                this.flowAddVariant.load = false

                // UPDATE BLOCKVARIANTS
                // const disttinticType01 = this.getDistinctTypesVariants(this.dataVariants, 'variant_type_id_1');
                // const disttinticType02 = this.getDistinctTypesVariants(this.dataVariants, 'variant_type_id_2');

                // this.flowAddVariant.variables.type_1 = disttinticType01[1]
                // this.flowAddVariant.variables.type_2 = disttinticType02[1]
            }, 1000);
        },

        closeAddVariant(){
            this.flowAddVariant.modal = false
            this.flowAddVariant.cloneId = {}
        },

        cloneVariant(value){
            this.flowAddVariant.cloneId = value
            this.flowAddVariant.modal = true
        },


        // MODAL EDT VARIABLE
        openModalVariant(value){
            this.modalVariant.data = value
            this.modalVariant.modal = true
            this.modalVariant.load = true

            apiMerchant.get(`/api/v2/market/store/warehouses`)
            .then(response => {
                this.modalVariant.warehouses = response.data
            })
            .catch(error => {
                console.log(error)
            })

            this.flowRegisterVariant.validate.status_id = undefined
            this.flowRegisterVariant.validate.category_id = undefined
            this.flowRegisterVariant.validate.name = undefined
            this.flowRegisterVariant.validate.slug = undefined
            this.flowRegisterVariant.validate.short_description = undefined
            this.flowRegisterVariant.validate.description = undefined
            this.flowRegisterVariant.validate.price = undefined
            this.flowRegisterVariant.validate.quantity_available = undefined
            this.flowRegisterVariant.validate.quantity_minimum = undefined
            this.flowRegisterVariant.validate.quantity_maximum = undefined
            this.flowRegisterVariant.validate.warehouse_id = undefined

            this.flowRegisterVariant.validateShipping.weight = undefined
            this.flowRegisterVariant.validateShipping.height = undefined
            this.flowRegisterVariant.validateShipping.width = undefined
            this.flowRegisterVariant.validateShipping.length = undefined
            this.flowRegisterVariant.validateShipping.cubing = undefined
            this.flowRegisterVariant.validateShipping.shipping_measure_unit_id = undefined

            if (this.modalVariant.data.price) {
                this.modalVariant.data.price = this.formatPriceFromApi(this.modalVariant.data.price)
            }

            setTimeout(() => {
                this.modalVariant.load = false
            }, 500);
        },
        

        // SEMANTICS
        handleChangeTag(tags) {
            this.modalVariant.data.semantics = tags;
        },


        // CHECK STATUS VARIANT
        checkStatusVariant(){
            if ( this.modalVariant.data.status_id == "d7b7bb9f-6cd5-4692-b296-6041141b2362" || this.modalVariant.data.status_id == "518c2025-1088-4c4e-9d14-4cb4e71a4bb8" ){
                if ( !this.modalVariant.data.price || this.modalVariant.data.price == "0,00" ){
                    this.flowRegisterVariant.validate.price = false

                    this.$toast.error("Informar o valor do produto")
                } else {
                    this.$toast.info("Revisar o valor do produto")
                }

                if ( !this.modalVariant.data.quantity_available || this.modalVariant.data.quantity_available == 0 ){
                    this.flowRegisterVariant.validate.quantity_available = false

                    this.$toast.error("Informar o estoque disponível")
                } else {
                    this.$toast.info("Revisar o estoque disponível")
                }
            }
        },


        // TAGS
        addTags() {
            if (this.flowRegisterVariant.attributes.Key && this.flowRegisterVariant.attributes.Value) {
                const newAttribute = {
                    Key: this.flowRegisterVariant.attributes.Key,
                    Value: this.flowRegisterVariant.attributes.Value
                }
        
                if (!this.modalVariant.data.attributes) {
                    this.modalVariant.data.attributes = []
                }

                this.modalVariant.data.attributes.push(newAttribute)
                this.flowRegisterVariant.attributes = { Key: '', Value: '' }
            }
        },
    
        removeTag(index) {
            this.modalVariant.data.attributes.splice(index, 1)
        },


        // PRICE
        applyMask(event) {
            let input = event.target;
            let price = input.value.replace(/[^0-9]/g, '');
            let originalSize = input.value.length;
            let cursorPosition = input.selectionStart;
        
            if (price === '') {
                this.modalVariant.data.price = '0,00';
                return;
            }
        
            price = price.replace(/^0+(?!$)/, '');
        
            if (price.length > 2) {
                price = price.slice(0, -2) + ',' + price.slice(-2);
            } else if (price.length === 2) {
                price = '0,' + price;
            } else if (price.length === 1) {
                price = '0,0' + price;
            }
        
            this.modalVariant.data.price = price;

            let newSize = price.length;
        
            cursorPosition = cursorPosition + (newSize - originalSize);
        
            input.setSelectionRange(cursorPosition, cursorPosition);
        },
    
        convertPriceDecimal(value) {
            return parseFloat(value.replace(',', '.'));
        },
    
        formatPriceFromApi(value) {
            if (value) {
                let updatedPrice = value.toString().replace('.', ',');
    
                if (!updatedPrice.includes(',')) {
                    updatedPrice += ',00';
                }
                
                else if (updatedPrice.split(',')[1].length === 1) {
                    updatedPrice += '0';
                }
    
                return updatedPrice;
            }
            return '0,00';
        },


        // MAX QUANTITY STOCK
        validateQuantity(field) {
            const maxLimit = 30000;
            
            if (this.modalVariant.data[field] > maxLimit) {
              this.flowRegisterVariant.validate[field] = false;
              this.modalVariant.data[field] = maxLimit;
            } else {
              this.flowRegisterVariant.validate[field] = true;
            }
        },


        // SHIPPING
        recalculateCubage() {
            const { length, width, height } = this.modalVariant.data.shipping
    
            if (length > 0 && width > 0 && height > 0) {
                let volume = 0
    
                if (this.unit === "cm") {
                    volume = (length / 100) * (width / 100) * (height / 100)
                } else if (this.unit === "inches") {
                    volume = (length / 12) * (width / 12) * (height / 12)
                }
    
                this.modalVariant.data.shipping.cubing = volume.toFixed(6)
            } else {
                this.modalVariant.data.shipping.cubing = null
            }
        },
    
        changeMeasureUnit() {
            const selectedMeasureUnit = this.dataMeasureUnit.find(
                unit => unit.Id === this.modalVariant.data.shipping.shipping_measure_unit_id
            );
            
            if (selectedMeasureUnit) {
                if (selectedMeasureUnit.Extra === 'metric') {
                    this.unit = 'cm'
                    this.weightUnit = 'kg'
                    this.volumeUnit = 'm³'
                } else if (selectedMeasureUnit.Extra === 'imperial') {
                    this.unit = 'inches'
                    this.weightUnit = 'lbs'
                    this.volumeUnit = 'ft³'
                }
    
                this.clearFields()
                this.recalculateCubage()
            }
        },
    
        clearFields() {
            this.modalVariant.data.shipping.length = 0
            this.modalVariant.data.shipping.width = 0
            this.modalVariant.data.shipping.height = 0
            this.modalVariant.data.shipping.weight = 0
    
            this.recalculateCubage();
        },
    
        selectPackage() {
            const newId = this.modalVariant.data.shipping.shipping_packaging_id
            const selectedPackage = this.dataPackages.find(pack => pack.id === newId)
    
            if (selectedPackage) {
                this.modalVariant.data.shipping.length = selectedPackage.length
                this.modalVariant.data.shipping.width = selectedPackage.width
                this.modalVariant.data.shipping.height = selectedPackage.height
    
                setTimeout(() => {
                    document.getElementById("measure_weight").focus()
                }, 50);
    
                this.recalculateCubage()
            }
        },
              


        // SAVE VARIANT
        saveVariant() {
            this.modalVariant.load = true
            
            this.validateModalVariant();
            const validatedInputsVariant = Object.values(this.flowRegisterVariant.validate).every(value => value === true)

            let validatedInputsVariantShipping = true

            if (this.modalVariant.data.shipping.shippable) {
                this.validateModalVariantShipping()
                validatedInputsVariantShipping = Object.values(this.flowRegisterVariant.validateShipping).every(value => value === true)
            }

            if (validatedInputsVariant && validatedInputsVariantShipping) {
                const precoParaApi = this.convertPriceDecimal(this.modalVariant.data.price);

                const dataToSend = {
                    ...this.modalVariant.data,
                    price: precoParaApi,
                }


                apiMerchant.put(`/api/v2/market/product/${this.dataProduct.id}/variants/${this.modalVariant.data.id}`, dataToSend)
                .then(response => {
                    const updatedVariant = response.data;
                    const indexToUpdate = this.dataVariants.findIndex(variable => variable.id === updatedVariant.id);

                    if (indexToUpdate !== -1) {
                        this.dataVariants[indexToUpdate] = updatedVariant;
                    }

                    this.$toast.success("Variante salva com sucesso!")
                    
                    if ( this.dataVariants.length == 1 ){
                        setTimeout(() => {
                            window.location.reload()
                        }, 300);
                    } else {
                        this.modalVariant.modal = false
                    }
                    
                    setTimeout(() => {
                        this.modalVariant.load = false
                    }, 1500);

                })
                .catch(error => {
                    console.log(error);
                    this.modalVariant.load = false;
                    this.$toast.error("Houve um erro ao salvar a variante");
                });
            } else {
                // this.$toast.error("Um ou mais campos estão sem preencher")

                console.log('Validações gerais:', this.flowRegisterVariant.validate)
                console.log('Validações de shipping:', this.flowRegisterVariant.validateShipping)
            }
        },
        

        // VALIDATE FORM MODAL VARIANT
        validateModalVariant() {
            this.modalVariant.load = true;
        
            this.flowRegisterVariant.validate = {};
        
            this.flowRegisterVariant.validate.status_id = this.modalVariant.data.status_id && this.modalVariant.data.status_id !== "00000000-0000-0000-0000-000000000000" ? true : false;
            this.flowRegisterVariant.validate.category_id = this.modalVariant.data.category_id ? true : false;
            this.flowRegisterVariant.validate.name = this.modalVariant.data.name ? true : false;
            this.flowRegisterVariant.validate.slug = this.modalVariant.data.slug ? true : false;
            this.flowRegisterVariant.validate.short_description = this.modalVariant.data.short_description ? true : false;
            this.flowRegisterVariant.validate.description = this.modalVariant.data.description ? true : false;
            this.flowRegisterVariant.validate.asset_id = this.modalVariant.data.asset_id ? true : false;

            if ( this.modalVariant.data.status_id == "d7b7bb9f-6cd5-4692-b296-6041141b2362" ){
                this.flowRegisterVariant.validate.quantity_available = this.modalVariant.data.quantity_available ? true : false;
                this.flowRegisterVariant.validate.quantity_minimum = this.modalVariant.data.quantity_minimum ? true : false;
                this.flowRegisterVariant.validate.quantity_maximum = this.modalVariant.data.quantity_maximum ? true : false;
            }

            this.flowRegisterVariant.validate.warehouse_id = this.modalVariant.data.warehouse_id ? true : false;
        
            const pricePattern = /^\d+,\d{2}$/;
            this.flowRegisterVariant.validate.price = pricePattern.test(this.modalVariant.data.price) && this.modalVariant.data.price !== "0,00";
        
            for (const [field, isValid] of Object.entries(this.flowRegisterVariant.validate)) {
                if (!isValid) {
                    const fieldName = {
                        status_id: "Status",
                        category_id: "Categoria",
                        name: "Nome",
                        slug: "Slug",
                        short_description: "Descrição curta",
                        description: "Descrição",
                        asset_id: "ID do ativo",
                        quantity_available: "Quantidade disponível",
                        quantity_minimum: "Quantidade mínima",
                        quantity_maximum: "Quantidade máxima",
                        warehouse_id: "ID do armazém",
                        price: "Preço"
                    }[field];
        
                    this.$toast.error(`O campo ${fieldName} está vazio ou inválido`);
                }
            }
        
            setTimeout(() => {
                this.modalVariant.load = false;
            }, 2000);
        },
                


        // VALIDATE FORM MODAL VARIANT SHIPPING
        validateModalVariantShipping() {
            this.flowRegisterVariant.validateShipping = {};
        
            this.flowRegisterVariant.validateShipping.weight = this.modalVariant.data.shipping.weight ? true : false;
            this.flowRegisterVariant.validateShipping.height = this.modalVariant.data.shipping.height ? true : false;
            this.flowRegisterVariant.validateShipping.width = this.modalVariant.data.shipping.width ? true : false;
            this.flowRegisterVariant.validateShipping.length = this.modalVariant.data.shipping.length ? true : false;
            this.flowRegisterVariant.validateShipping.cubing = this.modalVariant.data.shipping.cubing ? true : false;
            this.flowRegisterVariant.validateShipping.shipping_measure_unit_id = this.modalVariant.data.shipping.shipping_measure_unit_id && this.modalVariant.data.shipping.shipping_measure_unit_id !== "00000000-0000-0000-0000-000000000000" ? true : false;
        
            for (const [field, isValid] of Object.entries(this.flowRegisterVariant.validateShipping)) {
                if (!isValid) {
                    const fieldName = {
                        weight: "Peso",
                        height: "Altura",
                        width: "Largura",
                        length: "Comprimento",
                        cubing: "Cubagem",
                        shipping_measure_unit_id: "Unidade de medida do envio"
                    }[field];
        
                    this.$toast.error(`O campo ${fieldName} está vazio ou inválido`);
                }
            }
        },
        

        removeError(place, field, shipping) {
            if ( place == "product" ){
                if (this.dataProduct[field]) {
                  this.flowRegister.validate[field] = true
                } else {
                  this.flowRegister.validate[field] = false
                }
            }
            
            if ( place == "variant" ){
                if (this.modalVariant.data[field]) {
                  this.flowRegisterVariant.validate[field] = true
                } else {
                  this.flowRegisterVariant.validate[field] = false
                }
            }
            
            if ( place == "variant" && shipping == "shipping" ){
                if (this.modalVariant.data.shipping[field]) {
                  this.flowRegisterVariant.validateShipping[field] = true
                } else {
                  this.flowRegisterVariant.validateShipping[field] = false
                }
            }
        }
    },

    watch: {
        'modalVariant.data.shipping.length': 'recalculateCubage',
        'modalVariant.data.shipping.width': 'recalculateCubage',
        'modalVariant.data.shipping.height': 'recalculateCubage',
        'modalVariant.data.shipping.shipping_measure_unit_id': 'recalculateCubage'
    }
}