// PAGES
import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home/Home.vue'
import FilterBrand from '../views/Filter/Filter.vue'
import FilterStore from '../views/Filter/Filter.vue'
import FilterCategoryStore from '../views/Filter/Filter.vue'
import FilterCategory from '../views/Filter/Filter.vue'
import FilterSearch from '../views/Filter/Filter.vue'
import Product from '../views/Product/Product.vue'
import Service from '../views/Product/Service.vue'
import BecomeSeller from '../views/BecomeSeller/BecomeSeller.vue'
import TermsConditions from '../views/TermsConditions/TermsConditions.vue'
import Privacy from '../views/Privacy/Privacy.vue'
import CompanyRegistration from '../views/CompanyRegistration/CompanyRegistration.vue'

import NotFound from '../views/NotFound/NotFound.vue'


// ACCOUNT
import TokenAuth from '../views/Account/TokenAuth.vue'
import Profile from '../views/Account/Profile/Profile.vue'
import Purchases from '../views/Account/Purchases/Purchases.vue'
import PurchasesReturns from '../views/Account/Purchases/Returns.vue'
import PurchasesReturn from '../views/Account/Purchases/Return.vue'

import PurchasePDF from '../views/Account/Purchases/PurchasePDF.vue'


// MERCHANT
import Dashboard from '../views/Merchant/Dashboard/Dashboard.vue'
import Sales from '../views/Merchant/Sales/Sales.vue'
import SalesDetails from '../views/Merchant/Sales/SalesDetails.vue'
import Returns from '../views/Merchant/Returns/Returns.vue'
import Products from '../views/Merchant/Products/Products.vue'
import ProductsAdd from '../views/Merchant/Products/ProductsManager.vue'
import ProductsManager from '../views/Merchant/Products/ProductsManager.vue'
import Campaigns from '../views/Merchant/Campaigns/Campaigns.vue'
import Store from '../views/Merchant/Store/Store.vue'
import Company from '../views/Merchant/Company/Company.vue'
import CompanyRedirectAdd from '../views/Merchant/Company/CompanyRedirectAdd.vue'


// TEMP
import Results from '../views/Results/Results.vue'
import Category from '../views/Category/Category.vue'
import BrandTemp from '../views/Brand/Brand.vue'
import ProductTemp from '../views/Product/Product_bkp.vue'
import ProductTest from '../views/Product/Product.vue'


const routes = [
  { path: '/', name: 'redirect', redirect: '/en/', component: Home, meta: {title:'DNE Market'} },
  { path: '/:lang/', name: 'Home', component: Home, meta: {title:'DNE Market'} },
  // { path: '/:lang/filter/', name: 'Filter', component: Filter, meta: {title:'DNE Market'} },
  { path: '/:lang/results/', name: 'Results', component: Results, meta: {title:'DNE Market'} },
  { path: '/:lang/brand', name: 'BrandTemp', component: BrandTemp, meta: {title:'DNE Market'} },
  { path: '/:lang/category', name: 'Category', component: Category, meta: {title:'DNE Market'} },
  { path: '/:lang/product-store', name: 'ProductTemp', component: ProductTemp, meta: {title:'DNE Market'} },

  
  // BRAND PAGE
  { path: '/:lang/brand/:brand_reference/:slug', name: 'FilterBrand', component: FilterBrand, meta: {title:'DNE Market'} },
  
  // STORE PAGE
  { path: '/:lang/store/:store_reference/:slug', name: 'FilterStore', component: FilterStore, meta: {title:'DNE Market'} },
  
  // CATEGORY STORE PAGE
  { path: '/:lang/store/:category_reference/category/:category/:slug', name: 'FilterCategoryStore', component: FilterCategoryStore, meta: {title:'DNE Market'} },

  // CATEGORY PAGE
  { path: '/:lang/category/:category_reference/:slug', name: 'FilterCategory', component: FilterCategory, meta: {title:'DNE Market'} },
  
  // SEARCH PAGE
  { path: '/:lang/search/:query', name: 'FilterSearch', component: FilterSearch, meta: {title:'DNE Market'} },
  
  // PRODUCT PAGE
  { path: '/:lang/store/:store_reference/:slug/product/:product/variant/:variant', name: 'Product', component: Product, meta: {title:'DNE Market'} },
   
  
  // { path: '/:lang/detail/:slug/store/:refStore/product/:refProduct/variant/:refVariant', name: 'Product', component: Product, meta: {title:'DNE Market'} },
  { path: '/:lang/product/jogo-hogwarts-legacy-stardard-playstation-5/store/1000049406/product/1000053176', name: 'ProductTest', component: ProductTest, meta: {title:'DNE Market'} },

  { path: '/:lang/service', name: 'Service', component: Service, meta: {title:'DNE Market'} },
  { path: '/:lang/become-seller', name: 'BecomeSeller', component: BecomeSeller },
  { path: '/:lang/terms-and-conditions', name: 'TermsConditions', component: TermsConditions },
  { path: '/:lang/privacy', name: 'Privacy', component: Privacy },
  { path: '/:lang/company-registration', name: 'CompanyRegistration', component: CompanyRegistration },
  
  { path: '/:lang/:catchAll(.*)', component: NotFound, meta: {title:'DNE Market'} },
  
  // ACCOUNT
  { path: '/:lang/account/token-auth/', name: 'TokenAuth', component: TokenAuth },
  { path: '/:lang/account/profile', name: 'Profile', component: Profile, meta: {title:'DNE Market'} },
  { path: '/:lang/account/purchases', name: 'Purchases', component: Purchases, meta: {title:'DNE Market'} },
  { path: '/:lang/account/purchases/returns', name: 'PurchasesReturns', component: PurchasesReturns, meta: {title:'DNE Market'} },
  { path: '/:lang/account/purchases/return/:idReturn', name: 'PurchasesReturn', component: PurchasesReturn, meta: {title:'DNE Market'} },
  
  { path: '/:lang/generate-pdf-purchase/:orderId', name: 'PurchasePDF', component: PurchasePDF, meta: {title:'PDF | DNE Market'} },
  
  
  // MERCHANT
  { path: '/:lang/merchant/dashboard', name: 'Dashboard', component: Dashboard, meta: {title:'Dashboard | DNE Market'} },
  
  { path: '/:lang/merchant/sales', name: 'Sales', component: Sales, meta: {title:'DNE Market'} },
  { path: '/:lang/merchant/sales/:orderId', name: 'SalesDetails', component: SalesDetails, meta: {title:'DNE Market'} },
  
  { path: '/:lang/merchant/returns', name: 'Returns', component: Returns, meta: {title:'DNE Market'} },

  { path: '/:lang/merchant/products', name: 'Products', component: Products, meta: {title:'DNE Market'} },
  { path: '/:lang/merchant/products/add', name: 'ProductsAdd', component: ProductsAdd, meta: {title:'DNE Market'} },
  { path: '/:lang/merchant/products/manager/:productID', name: 'ProductsManager', component: ProductsManager, meta: {title:'DNE Market'} },
  
  { path: '/:lang/merchant/campaigns', name: 'Campaigns', component: Campaigns, meta: {title:'DNE Market'} },
  { path: '/:lang/merchant/store', name: 'Store', component: Store, meta: {title:'DNE Market'} },
  { path: '/:lang/merchant/company/:companyReference', name: 'Company', component: Company, meta: {title:'DNE Market'} },
  { path: '/:lang/add-store-redirect', name: 'CompanyRedirectAdd', component: CompanyRedirectAdd, meta: {title:'DNE Market'} },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  // Get the page title from the route meta data that we have defined
  // See further down below for how we setup this data
  const title = to.meta.title

  //Take the title from the parameters
  const titleFromParams = to.params.pageTitle;
  // If the route has a title, set it as the page title of the document/page
  if (title) {
    document.title = title
  }
  // If we have a title from the params, extend the title with the title
  // from our params
  if (titleFromParams) {
    document.title = `${titleFromParams} - ${document.title}`;
  }
  // Continue resolving the route
  next()
})


export default router
