<template>
    <div class="shipping">

        <!-- POSTAL CODE -->
        <div class="postal-code" v-if="!statusLogin">
            <div class="form-postal-code">
                <h3>{{getMyLanguage("ShippingAddress","destiny.form.postalcode")}}</h3>

                <div>
                    <input type="text" class="form"
                        v-model="shippingPostalCode.postal_code"
                        :placeholder="getMyLanguage('ShippingAddress','destiny.placeholder.postalcode')">

                    <span class="btn" @click="sendPostalCode()">Ok</span>
                </div>
            </div>

            <!-- <div class="msg accepted">
                <span>Cupom aplicado</span>
            </div> -->
        </div>
        

        <!-- LIST DESTINY -->
        <div v-if='statusLogin && flow.status === "none"'>
            <div class="shipping-to">
                <div class="no-selected" v-if="!dataSourceShipping.account_address_id">
                    {{getMyLanguage("ShippingAddress","no-address-selected")}}
                </div>

                <div class="destiny"
                    v-for="(listDestiny, index) in dataSourceAddresses"
                    :key="index"
                    :class="{selected: listDestiny.account_address_id === dataSourceShipping.account_address_id}">

                    <div class="address"
                        @click="selectAddress(listDestiny)"
                        :class="{default: listDestiny.account_address_default}">

                        <strong v-if="listDestiny.account_address_id === dataSourceShipping.account_address_id">
                            {{getMyLanguage("ShippingAddress","destiny.subtitle-address")}}
                        </strong>

                        <div>
                            <span>{{listDestiny.address_1}}</span>
                            <span>, {{listDestiny.address_number}}</span>
                            <span v-if="listDestiny.address_2 && (listDestiny.address_number != listDestiny.address_2)">
                                <small> (</small>{{listDestiny.address_2}}<small>)</small>
                            </span>
                        
                            <span>, {{listDestiny.address_city}}</span>
                            <span><small> / </small>{{listDestiny.address_state}}</span>
                            <span>, {{listDestiny.country_name}}</span>

                            <span>, {{listDestiny.address_postal_code}}</span>
                        </div>
                    </div>
                    
                    <div class="actions" v-if="!listDestiny.account_address_default">
                        <div class="edit" @click="edtForm(listDestiny)"></div>
                        <div class="delete" @click="delForm(listDestiny)"></div>
                    </div>
                </div>
            </div>

            <div class="btn-secondary" @click="addForm()">
                {{getMyLanguage("ShippingAddress","destiny.add-address")}}
            </div>
        </div>



        <!-- ADD -->
        <div class="add-edt" v-if='flow.status == "add" || flow.status == "edt"'>
            <h3 class="title-secondary" v-if='flow.status == "add"'>
                {{getMyLanguage("ShippingAddress","destiny.form.new-destiny.title")}}
            </h3>

            <h3 class="title-secondary" v-if='flow.status == "edt"'>
                {{getMyLanguage("ShippingAddress","destiny.form.edt-destiny.title")}}
            </h3>

            <label>
                {{getMyLanguage("ShippingAddress","destiny.form.address1")}}
                <input type="text" class="form" v-model="dataShipping.address_1">
            </label>

            <div>
                <label class="lbl-number">
                    {{getMyLanguage("ShippingAddress","destiny.form.number")}}
                    <input type="text" class="form" v-model="dataShipping.address_number">
                </label>

                <label class="lbl-postalcode">
                    {{getMyLanguage("ShippingAddress","destiny.form.postalcode")}}
                    <input type="text" class="form" v-model="dataShipping.address_postal_code">
                </label>
            </div>

            <label>
                {{getMyLanguage("ShippingAddress","destiny.form.address2")}}
                <input type="text" class="form" v-model="dataShipping.address_2">
            </label>

            <label>
                {{getMyLanguage("ShippingAddress","destiny.form.city")}}
                <input type="text" class="form" v-model="dataShipping.address_city">
            </label>

            <label>
                {{getMyLanguage("ShippingAddress","destiny.form.state")}}
                <input type="text" class="form" v-model="dataShipping.address_state">
            </label>
            
            <label>
                {{getMyLanguage("ShippingAddress","destiny.form.district")}}
                <input type="text" class="form" v-model="dataShipping.address_district">
            </label>

            <div class="autocomplete no-icon">
                <label>
                    {{getMyLanguage("ShippingAddress","destiny.form.country")}}

                    <div class="openAutocomplete"
                        v-if="!listCountryStatus"
                        @click="listCountry(true)"></div>

                    <div class="closeAutocomplete closeDropListCountry"
                        v-if="listCountryStatus"
                        @click="listCountry(false)"></div>

                    <img class="flag-selected"
                        :src="`https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/${selectedCountryFlag}/flags`"
                        v-if="selectedCountryFlag != false && selectedCountryFlag != null">

                    <input type="text" id="issuerCountry" class="form"
                        :class="{selected: selectedCountryFlag != false}"
                        @keyup="filterListCountry()"
                        @blur="listCountry(false), validateError"
                        :value="selectedCountry.name">

                    <ul id="drop-list" v-if="listCountryStatus">
                        <li
                            v-for="(itemList,index) in dataCountries"
                            :key="index"
                            @click="dataShipping.address_id = itemList.Id,
                            selectedCountry.name = itemList.Name,
                            selectedCountryFlag = itemList.Flag">

                            <img :src="`https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/${itemList.Flag}/flags`">
                            <a>{{ countryTranslate(itemList.IsoCode) }}</a>
                        </li>
                    </ul>
                </label>
            </div>



            <div class="bts">
                <div class="btn-cancel" @click='flow.status = "none"'></div>
                
                <div class="btn-primary btn-disabled" v-if="!dataShipping.address_1 || !dataShipping.address_number || !dataShipping.address_postal_code || !dataShipping.address_city || !dataShipping.address_state || !dataShipping.country_name">
                    {{getMyLanguage("ShippingAddress","destiny.form.bt-add")}}
                </div>

                <div class="btn-primary" @click="addAddress()" v-if="flow.status == 'add' && dataShipping.address_1 && dataShipping.address_number && dataShipping.address_postal_code && dataShipping.address_city && dataShipping.address_state && dataShipping.country_name">
                    {{getMyLanguage("ShippingAddress","destiny.form.bt-add")}}
                </div>

                <div class="btn-primary" @click="edtAddress()" v-if="flow.status == 'edt' && dataShipping.address_1 && dataShipping.address_number && dataShipping.address_postal_code && dataShipping.address_city && dataShipping.address_state && dataShipping.country_name">
                    {{getMyLanguage("ShippingAddress","destiny.form.bt-save")}}
                </div>
            </div>
        </div>


        <!-- DEL -->
        <div class="remove-destiny" v-if='flow.status == "del"'>
            <h3 class="title-secondary">
                {{getMyLanguage("ShippingAddress","destiny.form.remove-destiny.title")}}
            </h3>

            <div class="address">
                <div>
                    <span>{{dataShipping.address_1}}</span>
                    <span>, {{dataShipping.address_number}}</span>
                    <span v-if="dataShipping.address_2 && (dataShipping.address_number != dataShipping.address_2)">
                        <small> (</small>{{dataShipping.address_2}}<small>)</small>
                    </span>
                
                    <span>, {{dataShipping.address_city}}</span>
                    <span><small> / </small>{{dataShipping.address_state}}</span>
                    <span>, {{dataShipping.country_name}}</span>

                    <span>, {{dataShipping.address_postal_code}}</span>
                </div>
            </div>

            <p>
                <strong>{{getMyLanguage("ShippingAddress","destiny.form.remove-question")}}</strong>
            </p>

            <div class="bts">
                <div class="btn-cancel" @click='flow.status = "none"'></div>
                
                <div class="btn-primary" @click="delAddress()">
                    {{getMyLanguage("ShippingAddress","destiny.form.bt-yes")}}
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    import getMyContentLanguage from "@/services/contentLanguage.js";

    import countriesList from "@/services/listCountries.js";

    import apiMarket from '@/config/apiMarket.js'
    import apiPlatform from '@/config/apiPlatform.js';

    import bus from '@/eventBus';

    export default{
        props: {
            statusLogin: Boolean,
            dataSourceShipping: Object,
            dataSourceAddresses: Object
        },

        data (){
            return {
                flagSelected: "en",

                shippingPostalCode: {
                    country_id: "",
                    postal_code: "",
                    account_address_id: ""
                },
                
                dataShipping: {},
                
                dataCountries: [],
                listCountryStatus: false,
                selectedCountry: {},
                selectedCountryFlag: false,

                flow: {
                    status: "none",
                },

                
                dropListOpened: {
                    countryCompany: false,
                },

                listCountryCompanyStatus: false,
                selectedCountryFlagCompany: false,
                selectedCountryCompany: {},
            }
        },

        async mounted(){
            this.shippingPostalCode = this.dataSourceShipping
            if ( !this.shippingPostalCode.country_id ){
                this.shippingPostalCode.country_id = "acc8b9e4-d63b-442f-b2d3-ed5593e6459f"
            }

            // COUNTRIES
            this.dataCountries = JSON.parse(localStorage.getItem("allCountries"))

            if ( !this.dataCountries ){
                const resumeCountries = await apiPlatform.get('api/v1/platform/country')
                this.dataCountries = resumeCountries.data
                const thisCountries = JSON.stringify(this.dataCountries)
                localStorage.setItem("allCountries",thisCountries)
            }
        },

        methods: {
            getMyLanguage(group, code){
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code)
            },

            sendPostalCode(){
                apiMarket.put(`/api/v1/market/checkout/${this.$cookies.get("idCart")}`, this.shippingPostalCode)
                .then(response => {
                    bus.emit('updateMiniCart', response.data)
                    // document.location.reload(true)
                })
                .catch(error => {
                    console.log(error)
                })
            },

            selectAddress(value){
                this.shippingPostalCode.country_id = value.country_id
                this.shippingPostalCode.country_iso_code_alpha_3 = localStorage.getItem("countryIsoAlpha3")
                this.shippingPostalCode.postal_code = value.address_postal_code
                this.shippingPostalCode.account_address_id = value.account_address_id
                
                apiMarket.put(`/api/v1/market/checkout/${this.$cookies.get("idCart")}`, this.shippingPostalCode)
                .then(response => {
                    bus.emit('updateMiniCart', response.data)
                    // document.location.reload(true)
                })
                .catch(error => {
                    console.log(error)
                })
            },

            addForm(){
                this.flow.status = "add"

                this.dataShipping = {}
            },

            edtForm(value){
                this.flow.status = "edt"
                // this.edt = this.dataShipping[value]
                // this.edt.index = value

                this.dataShipping = value
            },

            delForm(value){
                this.flow.status = "del"
                // this.del = this.dataShipping[value]

                this.dataShipping = value
            },


            // COUNTRIES
            countryTranslate(code){
                return countriesList(code, this.flagSelected)
            },

            ordercountries() {
                this.countriesTranslated = [...this.dataCountries];

                this.countriesTranslated.forEach(lang => {
                    lang.translatedCountry = this.countryTranslate(lang.IsoCode);
                });

                this.countriesTranslated.sort((a, b) => {
                    return a.translatedCountry.toLowerCase().localeCompare(b.translatedCountry.toLowerCase());
                });
            },

            listCountry(status){
                setTimeout(() => {
                    this.listCountryStatus = status
                }, 250)
                
                if ( status ){
                    setTimeout(() => {
                        document.getElementById('issuerCountry').focus()
                    }, 10);
                }
            },

            filterListCountry() {
                let a = '';
                let i = '';
                let txtValue = '';
                const inputList = document.getElementById("issuerCountry");
                const filter = inputList.value.toUpperCase();
                const ul = document.getElementById("drop-list");
                const li = ul.getElementsByTagName("li");
                for (i = 0; i < li.length; i++) {
                    a = li[i].getElementsByTagName("a")[0];
                    txtValue = a.textContent || a.innerText;
                    if (txtValue.toUpperCase().indexOf(filter) > -1) {
                            li[i].style.display = "";
                    } else {
                            li[i].style.display = "none";
                    }
                }
            },



            addAddress(){
                this.flow.status = "none"

                // this.dataShipping.push({
                //     address1: this.add.address1,
                //     number: this.add.number,
                //     postalCode: this.add.postalCode,
                //     city: this.add.city,
                //     state: this.add.state,
                //     country: this.add.countryId,
                //     address2: this.add.address2
                // })

                this.toastSuccess("destiny.toast.add")
            },
            
            edtAddress(){
                this.flow.status = "none"
                
                this.toastSuccess("destiny.toast.updated")
                
                // this.dataShipping[this.edt.index].push({
                //     address1: this.edt.address1,
                //     number: this.edt.number,
                //     postalCode: this.edt.postalCode,
                //     city: this.edt.city,
                //     state: this.edt.state,
                //     country: this.edt.country,
                //     address2: this.edt.address2
                // })

            },

            delAddress(){
                this.flow.status = "none"
                // this.dataShipping.splice(index, 1)

                this.toastSuccess("destiny.toast.removed")
            },



            toastSuccess(value) {
                this.$toast.success(this.getMyLanguage("ShippingAddress",value));
            },
            
            toastError() {
                this.$toast.success(this.getMyLanguage("ShippingAddress","Houve um erro"));
            },
        },
    }
</script>



<style lang="scss" scoped>
    @import '@/assets/scss/_colors.scss';

    /* COUPON */
    .postal-code{
        .form-postal-code{
            h3{
                font-size: 18px;
                line-height: 20px;
                margin-bottom: 8px;
            }

            > div{
                display: flex;
                align-items: center;

                .form{
                    border: 1px solid #ccc;
                    border-right: none;
                    background: $color-text-tertiary;
                    border-radius: 12px 0 0 12px;
                    margin: 0;
                    height: 38px;
                    line-height: 38px;
                }

                .btn{
                    padding: 0 1rem;
                    font-size: 12px;
                    line-height: 38px;
                    height: 38px;
                    background: $color-primary;
                    color: $color-text-tertiary;
                    border-radius: 0 12px 12px 0;
                }
            }
        }

        .msg{
            margin-top: .5rem;
            padding: .5rem 1rem;
            border-radius: 6px;
            font-size: 13px;
            line-height: 17px;
            text-align: center;

            &.accepted{
                border: 1px solid rgba(74, 174, 87, 0.5);
                background: rgba(74, 174, 87, 0.1);
                color: $color-secondary;
            }

            &.denied{
                border: 1px solid rgba(235, 104, 52, 0.5);
                background: rgba(235, 104, 52, 0.1);
            }
        }
    }


    // SHIPPING
    .shipping{
        .shipping-to{
            display: flex;
            flex-direction: column;

            .no-selected{
                padding: 1rem;
                border-radius: 6px;
                text-align: center;
                margin-bottom: 1rem;
                background: rgba(235, 104, 52, 0.15);
                font-size: 14px;
                line-height: 18px;
                color: $color-pending;
            }

            .destiny{
                /* order: 2; */
                display: flex;
                align-items: center;
                position: relative;
                border: 1px solid #ddd;
                background: $color-text-tertiary;
                margin-bottom: 10px;

                &.selected{
                    .address{
                        background-image: url('~@/assets/images/icons/pin-active.svg') !important;
                    }
                }

                .address{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    width: calc(100% - 32px);
                    min-height: 64px;
                    padding: 10px 10px 10px 46px;
                    background: url('~@/assets/images/icons/pin.svg') no-repeat 10px center;
                    background-size: auto 34px;

                    &.default{
                        width: 100%;
                    }

                    strong{
                        position: absolute;
                        z-index: 10;
                        top: -15px;
                        left: 10px;
                        display: block;
                        height: 20px;
                        font-size: 12px;
                        line-height: 20px;
                        color: $color-text-tertiary;
                        background: $color-secondary;
                        padding: 0 15px;
                        margin-bottom: 3px;
                        animation: showLabel .6s alternate;
                    }

                    @keyframes showLabel {
                        0%{opacity: 0;}
                        40%{opacity: 0; left: 0px; color: transparent; border-color: $color-text-tertiary;}
                        100%{opacity: 1; left: 10px; color: $color-secondary; border-color: $color-secondary;}
                    }

                    div{
                        font-size: 12px;
                        line-height: 14px;
                    }
                }

                .actions{
                    position: absolute;
                    right: 2px;
                    top: 2px;
                    width: 30px;

                    div{
                        width: 30px;
                        height: 30px;
                        border-radius: 3px;
                        transition: .3s;
                        background-size: 16px auto !important;
                        cursor: pointer;

                        &.edit{
                            background: url('~@/assets/images/icons/edit.svg') no-repeat center;
                        }

                        &.delete{
                            background: url('~@/assets/images/icons/trash.svg') no-repeat center;
                        }
                    }
                }
            }


            /* SELECTED */
            .destiny.selected{
                /* order: 1; */
                border-color: $color-secondary;
                background: rgba(74, 174, 87, 0.1);
                animation: shippingTo 1s alternate;
                margin-top: 20px;
            }

            @keyframes shippingTo {
                0% {opacity: 0;}
                100% {opacity: 1;}
            }
        }

        .btn-secondary{
            margin-top: 10px;
        }


        /* ADD / EDT */
        .add-edt{
            position: relative;

            label{
                display: block;
                width: 100%;
                font-size: 13px;
                line-height: 15px;
                margin-bottom: 1rem;

                .form{
                    margin-top: 2px;
                    margin-bottom: 0;
                    height: 40px;
                    line-height: 40px;
                }
            }

            .autocomplete{
                z-index: 10;

                &.has-label ul{
                    top: 60px;
                }

                .openAutocomplete{
                    top: 17px;
                    height: 40px;
                }

                .closeAutocomplete{
                    top: 17px;
                    height: 40px;
                }

                .flag-selected{
                    top: 27px;
                }
            }

            > div{
                display: flex;
                justify-content: space-between;

                .lbl-number{
                    width: 35%;
                }

                .lbl-postalcode{
                    width: 60%;
                }
            }

            .overflow-autocomplete{
                display: block;
                position: absolute;
                z-index: 5;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                background: rgba(234, 234, 234, 0.7);
                animation: showAutocomplete .4s alternate;
            }

            @keyframes showAutocomplete {
                0% {opacity: 0;}
                100% {opacity: 1;}
            }
        }
    }


    // BTS
    .bts{
        padding-top: 1rem;
        
        .btn-primary{
            text-align: center;
            padding: 0 10px;
            line-height: 40px;
            height: 40px;
            width: 100%;
            justify-content: center;

            &:after{
                display: none;
            }
        }

        .btn-cancel{
            width: 60px;
            height: 40px;
            padding: 0;
            margin-right: 10px;
            border-radius: 12px;
            background: url("~@/assets/images/icons/arrow-white.svg") no-repeat #888 center;
            background-size: 16px auto;
            transform: rotate(-180deg);
        }
    }


    /* DELETE */
    .remove-destiny{
        .address{
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 100%;
            min-height: 64px;
            padding: 10px 10px 10px 46px;
            margin-bottom: 1rem;
            background: url('~@/assets/images/icons/pin.svg') no-repeat 10px center rgba(235, 104, 52, 0.2);
            background-size: auto 34px;

            div{
                font-size: 12px;
                line-height: 14px;
            }

            p{
                text-align: center;
                font-size: 14px;
                line-height: 18px;
                color: $color-pending;
            }

            .bts{
                display: flex;
                justify-content: center;

                .btn-primary{
                    background: $color-pending;
                }
            }
       }
    }
</style>