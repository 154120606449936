const $ = require('jquery')
window.$ = $

import apiInternational from '@/config/apiInternational.js'

export default {
    data(){
        return {
            modalDocument: {
                modal: false,
                status: "",
            },
            
            dataCompliance: [],
            
            showPending: false,
            showSent: false,

            uploadFileDocument: {
                File: null,
                selected: false,
                documentType: "1",
                loadUpload: false,
                isImage: false,
                resumeFile: false,
                previewURLImage: ""
            },

            dataSendDoc: {
                accountDocumentTypeCode: '',
                Observations: ''
            },

            errorsGetDocs: {
                dataCompliance: false
            },

            errorsPostDocs: {
                upload: false
            },
        }
    },

    async mounted(){
        
    },

    methods: {
        // async loadDocs(){
        //     const token = localStorage.getItem('accessToken');
        //     let code = localStorage.getItem('codeLanguage');
        //     let companyReference = this.dataMerchant.company.reference
    
        //     await apiInternational.get('/api/v1/international/compliance/company', { headers: { 'Authorization': `bearer ${token}`, 'content-language': code, 'Company-Reference': companyReference } })
        //     .then(response => {
        //         this.dataCompliance = response.data
        //     })
        //     .catch(error => {
        //         this.errorsGetDocs.dataCompliance = true
        //         console.log(error)
        //     })
        // },


        // checkMandatoryDocuments() {
        //     this.pendingMandatoryDocuments = this.dataMerchant.documents.some(doc => doc.account_document_type_mandatory === true && doc.status_extra !== "Loaded");
        // },

        maskedPhone(phoneNumber) {
            if (!phoneNumber) return ''
            const visibleStart = phoneNumber.slice(0, 2)
            const visibleEnd = phoneNumber.slice(-2)

            return `${visibleStart}****${visibleEnd}`
        },

        checkDocuments() {
            const mandatoryDocs = this.dataMerchant.documents.filter(
                (doc) => doc.account_document_type_mandatory === true
            );

            const hasPending = mandatoryDocs.some(
                (doc) =>
                    doc.status_extra !== "Accepted" &&
                    doc.status_extra !== "Loaded"
            );

            const allAcceptedOrLoaded = mandatoryDocs.every(
                (doc) =>
                    doc.status_extra === "Accepted" ||
                    doc.status_extra === "Loaded"
            );

            const hasLoaded = mandatoryDocs.some(
                (doc) => doc.status_extra === "Loaded"
            );

            this.showPending = hasPending;
            this.showSent = !hasPending && allAcceptedOrLoaded && hasLoaded;
        },


        openModalUploadDocument(typeCode, status){
            this.dataSendDoc.accountDocumentTypeCode = typeCode
            this.modalDocument.modal = true
            this.modalDocument.status = status
        },

        uploadDocument() {
            this.uploadFileDocument.File = this.$refs.fileDocument.files[0];
            
            if ( this.uploadFileDocument.File != null ){
                this.uploadFileDocument.selected = "fileSelected"
                
                const fileURL = URL.createObjectURL(this.uploadFileDocument.File);
                this.uploadFileDocument.previewURLImage = fileURL;
                this.uploadFileDocument.resumeFile = true
                
                setTimeout(() => {
                  URL.revokeObjectURL(fileURL);
                }, 10000);
            }

            if (this.uploadFileDocument.File.type.startsWith('image/')) {
                this.uploadFileDocument.isImage = true
            } else {
                this.uploadFileDocument.isImage = false
            }
        },

        formatFileSize(fileSize) {
            if (fileSize === 0) return '0 Bytes';
            const k = 1024;
            const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
            const i = Math.floor(Math.log(fileSize) / Math.log(k));
            return parseFloat((fileSize / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
        },


        // UPLOAD
        uploadDoc(){
            this.uploadFileDocument.loadUpload = true
            const formData = new FormData();
            formData.append('File', this.uploadFileDocument.File);
            formData.append('Type', this.dataSendDoc.accountDocumentTypeCode);
            formData.append('Observations', this.dataSendDoc.Observations);
            const token = localStorage.getItem('accessToken');
            const headers = { 'Content-Type': 'multipart/form-data', 'Authorization': `bearer ${token}`, 'Company-Reference': this.dataMerchant.reference };
            
            apiInternational.post("/api/v1/international/compliance/company/document", formData, { headers })
            .then(response => {
                console.log(response)
                console.clear()
                
                location.reload()
            })
            .catch(error => {
                console.log(error)
                this.errorsPostDocs.upload = "error upload"

                setTimeout(() => {
                    this.errorsPostDocs.upload = false
                    this.uploadFileDocument.loadUpload = false
                }, 10000);
            })
        },
    }
}