import apiMerchant from '@/config/apiMerchant.js'

export default {
    data(){
        return {
            load: false,

            flowSave: {
                load: false,
                section: "",
                modalConfirm: false,
                confirmAccepted: false,
            },

            dataMerchant: [],

            storeDetails: {
                address: ""
            },

            errorsPostSave: {
                dataMerchant: false,
            },
        }
    },

    methods: {
        // SAVE STEP
        saveSection(value){
            this.flowSave.load = true
            this.flowSave.section = value

            if ( this.flowSave.section == "layout" ){                
                
                // SAVE
                apiMerchant.post("/api/v1/market/store/store", this.dataMerchant.store)
                .then(response => {
                    this.dataMerchant.store = response.data

                    if ( this.uploadFileLogo.File || this.uploadFileHeaderStore.File ){
                        this.saveUpload()
                    } else {
                        this.goTop()

                        setTimeout(() => {
                            this.flowSave.load = false
                            this.flowSave.modalConfirm = false
                            this.goTop()
                            this.$toast.success("Dados atualizados com sucesso")
                        }, 500);
                    }
                })
                .catch(error => {
                    this.$toast.error("Houve um erro na atualização dos dados")
                    console.log(error)
                    this.errorsPostSave = error.data
                })
            }
        },

        saveUpload(){
            const formData = new FormData();
            formData.append('logo', this.uploadFileLogo.File);
            formData.append('banner', this.uploadFileHeaderStore.File);

            const token = localStorage.getItem('accessToken');
            const headers = { 'Content-Type': 'multipart/form-data', 'Authorization': `bearer ${token}`};
            
            apiMerchant.post("/api/v1/market/store/store/attachments", formData, { headers })
            .then(response => {
                console.log(response)
                console.clear()

                location.reload()
            })
            .catch(error => {
                console.log(error)

                this.flowSave.load = false
                this.flowSave.modalConfirm = false
                this.goTop()

                if (error.response.data == "tamanho"){
                    this.$toast.error("Tamanho máximo: 30mb cada imagem")
                } else {
                    this.$toast.error("Houve um erro no upload")
                }
            })
        },


        // saveStep(){
        //     this.load = true
        //     const register = this.dataMerchant;

        //     let dataPhoneCompany = this.validatePhone.company.val
        //     let dataPhonePerson = this.validatePhone.person.val

        //     this.dataMerchant.companyProfile.phoneNumber = dataPhoneCompany.replace(/[^0-9]/g,'')
        //     this.dataMerchant.phoneNumber = dataPhonePerson.replace(/[^0-9]/g,'')
            

        //     apiMarket.post("/api/v1/platform/account/register/", register)
        //     .then(response => {
        //         this.registerId = response.data.id;
        //         const languageRedirect = String(window.location.pathname).split("/")[1];
        //         window.location.href = `/${languageRedirect}/account/confirm-email-invite/${this.$route.params.registerId}`
        //     })
        //     .catch(error => {
        //         console.log(error.response.data)

        //         if ( error.response.data === "account_activate_email_already_exists" || error.response.data === "account_activate_phone_already_exists" ) {
        //             this.errorsPostSave.dataMerchant = error.response.data
        //         } else if ( error.response.data === "not_allowed_restricted_country_pep" ) {
        //             this.errorsPostSave.country_pep = true
        //         } else{
        //             this.errorsPostSave.general = error.response.status

        //             setTimeout(() => {
        //                 this.errorsPostSave.general = false
        //             }, 4000);
        //         }

        //         console.log(this.errorsPostSave)

        //         setTimeout(() => {
        //             this.load = false
        //         }, 500);
        //     })
        // },


        // ADD ADDRESS
        addAddress(type){
            this.flowAddressAdd.modal = true
            this.flowAddressAdd.status = "new"
            this.flowAddressAdd.address.address_type_code = type
            this.flowAddressAdd.address.name = `${type} Address`
        },

        saveAddress(){
            this.flowAddressAdd.load = true

            apiMerchant.post('/api/v1/platform/account/address', this.flowAddressAdd.address)
            .then(response => {
                console.log(response.data)
                console.clear()

                location.reload()

                // this.dataMerchant.store.addresses = response.data
                // this.$toast.success("Endereço adicionado com sucesso")

                // checked no novo endereço...

                // setTimeout(() => {
                //     this.flowAddressAdd.load = false
                //     this.flowAddressAdd.modal = false
                // }, 500);
            })
            .catch(error => {
                console.log(error)

                setTimeout(() => {
                    this.flowAddressAdd.status = "error"
                    this.flowAddressAdd.load = false
                }, 500);
            })
        },

        removeAddress(value, address){
            if ( value == "start" ){
                this.flowAddressRemove.modal = true
                this.flowAddressRemove.load = true
                this.flowAddressRemove.address = address
    
                setTimeout(() => {
                    this.flowAddressRemove.load = false
                }, 500);
            }
            else if ( value == "confirm" ){
                this.flowAddressRemove.load = true

                // then
                setTimeout(() => {
                    this.flowAddressRemove.modal = false
                    this.flowAddressRemove.load = false
                    this.$toast.success("Endereço removido com sucesso")
                }, 500);
            }
        }
    }
}