<template>
    <div class="region-filter">
        <h4>{{getMyLanguage("Search","region.title-filter")}}</h4>

        <div class="regions">
            <div class="scroll">
                <span class="back"></span>

                <a :href="region.slug" v-for="(region, index) in dataSource" :key="index">
                    {{ countryTranslate(region.IsoCode) }}
                    <small>({{region.quantity}})</small>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
    import getMyContentLanguage from "@/services/contentLanguage.js";
    import countriesList from "@/services/listCountries.js";

    export default {
        props: {
            dataSource: {
                type: Array
            },
        },

        data (){
            return {
                flagSelected: "en",
            }
        },

        methods: {
            getMyLanguage(group, code) {
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code);
            },

            countryTranslate(code){
                return countriesList(code, this.flagSelected)
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/_colors.scss';
    @import '@/assets/scss/_dneElements.scss';

    .region-filter{
        margin-bottom: 30px;
        
        h4{
            display: flex;
            margin-bottom: 10px;

            @media (max-width: 576px){
                font-size: 14px;
                line-height: 20px;
            }
            
            @media (min-width: 576px){
                font-size: 16px;
                line-height: 22px;
            }

            span{
                margin-left: 15px;
            }
        }

        .regions{
            display: flex;

            .scroll{
                @include scrollbars(6px, $color-secondary, #ddd);
                display: flex;
                // width: calc(100% - 45px);
                padding-bottom: 10px;
                overflow-x: auto;
            }

            span, a{
                display: flex;
                align-items: center;
                padding: 0 10px;
                margin: 0 5px 5px 0;
                background-color: #eee;
                border-radius: 15px;
                transition: .3s;
                font-size: 11px;
                line-height: 30px;
                height: 30px;
                font-weight: 600;
                cursor: pointer;

                &.back{
                    display: block;
                    // width: 40px;
                    padding: 0 20px;
                    background: url('~@/assets/images/icons/back.svg') no-repeat center #eee;
                    background-size: auto 12px;
                }

                @media (min-width: 992px){
                    &:hover{
                        background-color: #ddd;
                    }
                }

                small{
                    display: block;
                    margin-left: 3px;
                    color: $color-secondary;
                }
            }
        }
    }
</style>