const $ = require('jquery')
window.$ = $
    
import getMyContentLanguage from "@/services/contentLanguage.js";
import langsList from "@/services/listLanguages.js";
import countriesList from "@/services/listCountries.js";

import apiMarket from '@/config/apiMarket.js'
// import apiMerchant from '@/config/apiMerchant.js'
import apiPlatform from '@/config/apiPlatform.js'
// import apiPay from '@/config/apiPay.js'

import { handleError } from '@/services/errorHandler.js'

import bus from '@/eventBus';
import moment from 'moment';

export default {
    data(){
        return{
            load: true,

            headerTemplate: "merchant",
            breadcrumb: [],

            blockCreate: "",

            flowStep: {
                load: false,
                stp: "step01",

                statusStep01: false,
                statusStep02: false,
                statusStep03: false
            },


            // MODAL CONCEPT
            modalConcept: {
                load: false,
                modal: false
            },
            

            // MODAL PARTNER
            modalPartner: {
                load: false,
                modal: false,
                type: "",
                partner: {},
                loadBtn: false,
            },


            // MODAL REMOVE COMPANY
            modalRemoveCompany: {
                load: false,
                modal: false
            },


            // MODAL PHONE VALIDATE
            modalPhoneValidate: false,

            codeSMS: {
                code: ''
            },

            flowSendSMS: {
                load: false,
                smsCodeShow: false,
                btnResendSMS: false,
                success: false,
                countDownResendCode: 0
            },

            errorsFlowSendSMS: {
                flow: false,
                invalidCode: false
            },


            dataCompanyType: [],
            dataCompanyIndustry: [],
            dataCurrency: [],
            dataCountry: [],
            dataLanguage: [],
            dataMerchantTypes: [],
            dataPersonGender: [],


            // ERRORS API
            errorsGetGeneral: {
                dataCompanyType: false,
                dataCompanyIndustry: false,
                dataCurrency: false,
                dataCountry: false,
                dataLanguage: false,
                dataMerchantTypes: false,
                dataPersonGender: false,
                dataMerchant: false,
            },

            errorsPostGeneral: {
                dataMerchant: false,
                country_pep: false,
                general: false
            },

            validateEmail: {
                company: {
                    regex: '',
                    status: '',
                    val: ''
                },

                partner: {
                    regex: '',
                    status: '',
                    val: ''
                }
            },
            
            validatePhone: {
                company: {
                    regex: '',
                    status: '',
                    val: ''
                },
                
                partner: {
                    regex: '',
                    status: '',
                    val: ''
                },

                contact01: {
                    regex: '',
                    status: '',
                    val: ''
                },

                contact02: {
                    regex: '',
                    status: '',
                    val: ''
                },

                dock: {
                    regex: '',
                    status: '',
                    val: ''
                }
            },


            newAddress: {
                countryId: '',
                phoneCountryId: '',
                phoneNumber: '',
                // ...
            },

            
            flagSelected: "en",
            showFlag: false,
            showFlagCompany: false,

            listCurrenciesCompanyStatus: false,
            listCurrenciesMerchantStatus: false,

            listLanguagesStatus: false,
            selectedLanguage: {},
            selectedLanguageFlag: false,
            
            listLanguagesCompanyStatus: false,
            selectedLanguageCompany: {},
            selectedLanguageCompanyFlag: false,
            
            listLanguagesSellerStatus: false,
            selectedLanguageSeller: {},
            selectedLanguageSellerFlag: false,
            
            listLanguagesContactStatus: false,
            selectedLanguageContact: {},
            selectedLanguageContactFlag: false,
            
            listLanguagesDockStatus: false,
            selectedLanguageDock: {},
            selectedLanguageDockFlag: false,

            listPhoneCountryCompanyStatus: false,
            selectedPhoneCountryCompany: {},
            selectedPhoneCountryFlagCompany: false,

            listCountryCompanyStatus: false,
            selectedCountryCompany: {},
            selectedCountryFlagCompany: false,
            
            listCountrySellerStatus: false,
            selectedCountrySeller: {},
            selectedCountryFlagSeller: false,
            
            listCountryPhoneSellerStatus: false,
            selectedCountryPhoneSeller: {},
            selectedCountryFlagPhoneSeller: false,
            
            listCountryNationalityStatus: false,
            selectedCountryNationality: {},
            selectedCountryFlagNationality: false,
            
            listCountryAddStatus: false,
            selectedCountryAdd: {},
            selectedCountryFlagAddAddress: false,

            selectedCountryDDICompany: {
                changeMask: true,
                phoneMask: '?',
                countrySelected: false,
                flag: ''
            },
            
            selectedCountryDDIPhoneSeller: {
                changeMask: true,
                phoneMask: '?',
                countrySelected: false,
                flag: ''
            },
            
            selectedCountryDDIContact: {
                changeMask: true,
                phoneMask: '?',
                countrySelected: false,
                flag: ''
            },
            
            selectedCountryDDIDock: {
                changeMask: true,
                phoneMask: '?',
                countrySelected: false,
                flag: ''
            },

            dropListOpened: {
                currencyCompany: false,
                languageCompany: false,
                countryPhoneCompany: false,
                countryCompany: false,
                ddiCompany: false,
                
                currencySeller: false,
                languageSeller: false,
                countrySeller: false,
                ddiSeller: false,
            },

            accept: {
                terms: false,
                notify: false,
                gdpr: false
            },

            resendLoad: false,
        }
    }, 

    async mounted(){
        
        // COMPANY TYPE
        await apiPlatform.get('/api/v1/public/attributes/codes/company_type')
        .then(response => {
            this.dataCompanyType = response.data
        })
        .catch(error => {
            this.errorsGetGeneral.dataCompanyType = error.response.data
        })

        
        // COMPANY SEGMENT / INDUSTRY
        await apiPlatform.get('/api/v1/public/attributes/codes/company_industry')
        .then(response => {
            this.dataCompanyIndustry = response.data
        })
        .catch(error => {
            this.errorsGetGeneral.dataCompanyIndustry = error.response.data
        })

        this.checkProfile()


        // ASSET TYPE / FIAT
        // await apiPay.get('/api/v1/pay/asset/type/fiat')
        // .then(response => {
        //     this.dataCurrency = response.data
        //     console.log(this.dataCurrency)
        //     console.log(this.store.state.allCurrencies)
        // })
        // .catch(error => {
        //     this.errorsGetGeneral.dataCurrency = error.response.data
        //     console.log(error)
        // })
        
        // this.dataCurrency = this.$store.state.allCurrencies
        

        // COUNTRY
        // await apiPlatform.get('/api/v1/platform/country')
        // .then(response => {
        //     this.dataCountry = response.data
        // })
        // .catch(error => {
        //     this.errorsGetGeneral.dataCountry = error.response.data
        // })
        
        // this.dataCountry = this.$store.state.allCountries


        // LANGUAGES
        // await apiPlatform.get('api/v1/platform/language/dne_market')
        // .then(response => {
        //     this.dataLanguage = response.data
        //     console.log(this.dataLanguage)
        // })
        // .catch(error => {
        //     this.errorsGetGeneral.dataLanguage = error.response.data
        // })

        // this.dataLanguage = this.$store.state.allLanguages
        
        
        // POSITION COMPANY / MERCHANT TYPES
        // await apiMerchant.get('/api/v1/platform/account/merchant/types')
        await apiPlatform.get('/api/v1/international/company/members/types')
        .then(response => {
            this.dataMerchantTypes = response.data
        })
        .catch(error => {
            this.errorsGetGeneral.dataMerchantTypes = error.response.data
        })
        
        
        // PERSON GENDER
        await apiPlatform.get('/api/v1/public/attributes/codes/person_gender')
        .then(response => {
            this.dataPersonGender = response.data
        })
        .catch(error => {
            this.errorsGetGeneral.dataPersonGender = error.response.data
        })
        
        
        this.breadcrumb = [
            {
                slug: `${this.flagSelected}/merchant/dashboard`,
                name: this.getMyLanguage("Merchant","breadcrumb.dashboard")
            },
            {
                name: this.getMyLanguage("Merchant","breadcrumb.manage-company")
            }
        ]
    },
    
    methods: {
        getMyLanguage(group, code){
            return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code)
        },

        // LANGUAGES
        languagesList(code){
            return langsList(code, this.$store.state.SelectLanguage.code)
        },

        orderLangs() {
            this.langsTranslated = [...this.$store.state.allLanguages];

            this.langsTranslated.forEach(lang => {
                lang.translatedName = this.languagesList(lang.code);
            });

            this.langsTranslated.sort((a, b) => {
                return a.translatedName.toLowerCase().localeCompare(b.translatedName.toLowerCase());
            });
        },
        
        
        // COUNTRIES
        countryTranslate(code){
            return countriesList(code, this.$store.state.SelectLanguage.code)
        },

        ordercountries() {
            this.countriesTranslated = [...this.$store.state.allCountries];

            this.countriesTranslated.forEach(lang => {
                lang.translatedCountry = this.countryTranslate(lang.IsoCode);
            });

            this.countriesTranslated.sort((a, b) => {
                return a.translatedCountry.toLowerCase().localeCompare(b.translatedCountry.toLowerCase());
            });
        },

        languageSelected(){
            this.flagSelected = this.$store.state.SelectLanguage.code
        },

        goTop(){
            $('html, body').animate({scrollTop : 0},200);
            return false;
        },

        dateTime(value) {
            const format = this.flagSelected === 'en' ? 'YYYY/MM/DD' : 'DD/MM/YYYY';
            return moment(value).format(format);
        },

        // PROFILE
        checkProfile(){
            if ( this.$route.params.companyReference == "0" && this.$store.state.profile.account_type != "Person" ){
                this.blockCreate = true
            } else {
                this.blockCreate = false
            }
        },

        checkStores(){
            if ( this.dataCountry && this.dataLanguage && this.dataCurrency ) {
                this.loadProfile()
            }
        },

        async loadProfile(){
            await apiPlatform.get(`/api/v1/international/company/${this.$route.params.companyReference}`)
            .then(response => {
                this.dataMerchant = response.data
                // this.dataMerchant.dob = moment(this.dataMerchant.dob).format('YYYY-MM-DD');

                this.dataMerchant.concept = JSON.parse(localStorage.getItem('concept')) || false

                this.selectedCountryDDICompany.ddi = this.dataMerchant.phone_country_ddi
                this.selectedCountryDDICompany.phoneMask = this.dataCountry.filter(mask => mask.Id == this.dataMerchant.phone_country_id)[0].PhoneMask

                if (this.dataMerchant.members && Array.isArray(this.dataMerchant.members)) {
                    this.dataMerchant.members = this.dataMerchant.members.map(member => {
                        return {
                            ...member,
                            dob: moment(member.dob).format('YYYY-MM-DD')
                        };
                    });
                }


                // console.log(this.dataMerchant)
                // console.log(this.$store.state.myBusiness[0])

                if ( this.$route.params.companyReference == "0" ){
                    this.newCompany = true
                } else {
                    this.validateEmail.company.val = this.dataMerchant.email
                    this.validatePhone.company.val = this.dataMerchant.phone_number

                    this.regexEmail("company")
                    this.regexPhone("company")
                }

                if ( !this.dataMerchant.concept ){
                    this.modalConcept.modal = true
                }

                // this.loadDocs()

                // COUNTRY PHONE COMPANY
                if ( this.dataMerchant.country_id ){
                    let selectedCC
                    selectedCC = this.dataCountry.filter(cc => cc.Id == this.dataMerchant.phone_country_id)

                    this.selectedPhoneCountryCompany.isocode = selectedCC[0].IsoCode
                    this.selectedPhoneCountryCompany.ddi = selectedCC[0].DDI
                    this.selectedPhoneCountryCompany.phoneMask = selectedCC[0].PhoneMask
                    this.selectedPhoneCountryFlagCompany = selectedCC[0].Flag
                }

                // COUNTRY COMPANY
                if ( this.dataMerchant.country_id ){
                    let selectedCC
                    selectedCC = this.dataCountry.filter(cc => cc.Id == this.dataMerchant.country_id)

                    this.selectedCountryCompany.isocode = selectedCC[0].IsoCode
                    this.selectedCountryCompany.ddi = selectedCC[0].DDI
                    this.selectedCountryCompany.phoneMask = selectedCC[0].PhoneMask
                    this.selectedCountryFlagCompany = selectedCC[0].Flag
                }
                
                // LANGUAGE COMPANY
                if ( this.dataMerchant.language_id ){
                    let selectedLanguage
                    selectedLanguage = this.dataLanguage.filter(companyLang => companyLang.id == this.dataMerchant.language_id)

                    this.selectedLanguageCompany.code = selectedLanguage[0].code
                    this.selectedLanguageCompanyFlag = selectedLanguage[0].flag
                }

                // COUNTRY MERCHANT
                if ( this.dataMerchant.country_id ){
                    let selectedCM
                    selectedCM = this.dataCountry.filter(cc => cc.Id == this.dataMerchant.country_id )

                    this.selectedCountrySeller.isocode = selectedCM[0].IsoCode
                    this.selectedCountryFlagSeller = selectedCM[0].Flag
                }
                
                // COUNTRY PARTNER
                if ( this.modalPartner.partner.country_id ){
                    let selectedCM
                    selectedCM = this.dataCountry.filter(cc => cc.Id == this.modalPartner.partner.country_id )

                    this.selectedCountryPhoneSeller.isocode = selectedCM[0].IsoCode
                    this.selectedCountryFlagPhoneSeller = selectedCM[0].Flag
                }
                
                // LANGUAGE MERCHANT
                // if ( this.dataMerchant.language_id ){
                //     let selectedLanguage
                //     selectedLanguage = this.dataLanguage.filter(merchantLang => merchantLang.id == this.dataMerchant.language_id )
                    
                //     this.selectedLanguageSeller.code = selectedLanguage[0].code
                //     this.selectedLanguageSellerFlag = selectedLanguage[0].flag
                // }
                
                // COUNTRY NATIONALITY
                if ( this.dataMerchant.country_id ){
                    let selectedNT
                    selectedNT = this.dataCountry.filter(cc => cc.Id == this.dataMerchant.country_id )

                    this.selectedCountryNationality.isocode = selectedNT[0].IsoCode
                    this.selectedCountryFlagNationality = selectedNT[0].Flag
                }
                
                // COUNTRY NEW ADDRESS
                if ( this.newAddress.country_id ){
                    let selectedNA
                    selectedNA = this.dataCountry.filter(cc => cc.id == this.newAddress.country_id )

                    this.selectedCountryContact.isocode = selectedNA[0].IsoCode
                    this.selectedCountryFlagAddAddress = selectedNA[0].flag
                }

                setTimeout(() => {
                    this.load = false
                }, 500);


                // CHECK DOCUMENTS
                setTimeout(() => {
                    this.checkDocuments()
                }, 600);
                
                // console.log(this.dataCountry.filter(cc => cc.id == this.dataMerchant.address.countryId)[0].name)
                
                // TEMP
                // this.dataMerchant.gender_id = "3013e802-c8c7-48bf-a1f5-602fff602c7d"
            })
            .catch(error => {
                this.load = false
                this.errorsGetGeneral.dataMerchant = JSON.stringify(error.response.status)

                if ( error.response.status == 401 ){
                    bus.emit('open-login',"401");
                }

                handleError(error, this.flagSelected, this.$store.state.profile.account_type);
            })



            const urlParams = new URLSearchParams(window.location.search);
            if ( urlParams.size > 0 ){
                if ( urlParams.get("guide") == "company" ){
                    setTimeout(() => {
                        this.navStep("stp01")
                    }, 600);
                }
                
                if ( urlParams.get("guide") == "partners" ){
                    setTimeout(() => {
                        this.navStep("stp02")
                    }, 600);
                }
                
                if ( urlParams.get("guide") == "documents" ){
                    setTimeout(() => {
                        this.navStep("stp03")
                    }, 600);
                }
            }
        },


        // NAVIGATION STEPS
        navStep(value) {
            const updateUrlParameter = (param, value) => {
                const url = new URL(window.location.href);
                url.searchParams.set(param, value);
                history.replaceState(null, '', url);
            };
        
            if (value == "stp01") {
                this.goTop();
                this.flowStep.load = true;
                updateUrlParameter('guide', 'company');
        
                setTimeout(() => {
                    this.flowStep.load = false;
                    this.flowStep.stp = "step01";
                }, 500);
            }
            
            if (value == "stp02") {
                this.goTop();
                this.flowStep.load = true;
                updateUrlParameter('guide', 'partners');
        
                setTimeout(() => {
                    this.flowStep.load = false;
                    this.flowStep.stp = "step02";
                }, 500);
            }
            
            if (value == "stp03") {
                this.goTop();
                this.flowStep.load = true;
                updateUrlParameter('guide', 'documents');
        
                setTimeout(() => {
                    this.flowStep.load = false;
                    this.flowStep.stp = "step03";
                }, 500);
            }
        },
                


        // CONCEPT
        toggleConcept() {
            this.dataMerchant.concept = !this.dataMerchant.concept;
            localStorage.setItem('concept', JSON.stringify(this.dataMerchant.concept));
        },

        concept(){
            this.modalConcept.load = true
            this.dataMerchant.concept = true

            setTimeout(() => {
                this.modalConcept.modal = false
                this.modalConcept.load = false
            }, 600);
        },


        // MODAL REMOVE COMPANY
        openModalRemoveCompany(){
            this.modalRemoveCompany.modal = true
            this.modalRemoveCompany.load = true

            setTimeout(() => {
                this.modalRemoveCompany.load = false
            }, 500);
        },


        // MODAL PARTNERS
        modalClosePartner(){
            this.modalPartner.modal = false
            this.modalPartner.type = ""
            this.modalPartner.partner.phone_country_ddi = ""
            // this.modalPartner.partner = []
            // this.modalPartner.load = false
            // this.modalPartner.key = ""
        },

        modalViewPartner(value, key){
            this.modalPartner.modal = true
            this.modalPartner.load = true
            this.modalPartner.key = key

            if ( !value ){
                this.modalPartner.type = "new"

                apiPlatform.get(`/api/v1/international/company/${this.dataMerchant.reference}/members/0`)
                .then(response => {
                    this.modalPartner.partner = response.data
                    this.modalPartner.partner.dob = moment(this.modalPartner.partner.dob).format('YYYY-MM-DD');

                    this.selectedCountryFlagPhoneSeller = this.dataCountry.filter(flag => flag.Id == this.modalPartner.partner.phone_country_id)[0].Flag
                    this.selectedCountryDDIPhoneSeller.phoneMask = this.dataCountry.filter(mask => mask.Id == this.modalPartner.partner.phone_country_id)[0].PhoneMask
                    this.selectedCountryDDIPhoneSeller.ddi = this.dataCountry.filter(ddi => ddi.Id == this.modalPartner.partner.phone_country_id)[0].DDI
                })

                
                setTimeout(() => {
                    this.modalPartner.load = false
                }, 500);

            } else {
                this.modalPartner.type = "edt"

                this.modalPartner.partner = value
                
                this.selectedLanguageSeller.code = this.dataLanguage.filter(lang => lang.id == this.modalPartner.partner.language_id)[0].code
                this.selectedLanguageSellerFlag = this.dataLanguage.filter(lang => lang.id == this.modalPartner.partner.language_id)[0].flag

                this.selectedCountryNationality.isocode = this.dataCountry.filter(nat => nat.Id == this.modalPartner.partner.country_id)[0].IsoCode
                this.selectedCountryFlagNationality = this.dataCountry.filter(nat => nat.Id == this.modalPartner.partner.country_id)[0].Flag
                
                this.selectedCountryDDIPhoneSeller.ddi = this.dataCountry.filter(ddi => ddi.Id == this.modalPartner.partner.phone_country_id)[0].DDI
                this.selectedCountryFlagPhoneSeller = this.dataCountry.filter(flag => flag.Id == this.modalPartner.partner.phone_country_id)[0].Flag
                
                this.selectedCountrySeller.isocode = this.dataCountry.filter(nat => nat.Id == this.modalPartner.partner.address.country_id)[0].IsoCode
                this.selectedCountryFlagSeller = this.dataCountry.filter(nat => nat.Id == this.modalPartner.partner.address.country_id)[0].Flag
                
                setTimeout(() => {
                    this.modalPartner.load = false
                }, 500);
            }
        },


        
        // PHONE VALIDATE
        async getCodeSend(){
            this.flowSendSMS.smsCodeShow = false
            this.flowSendSMS.load = true
            this.errors.invalidCode = false
            this.codeSMS.code = ''

            await apiMarket.get('/api/v1/platform/security/phone/validate')
            .then(response => {
                console.log(response)
                console.clear()

                setTimeout(() => {
                    this.flowSendSMS.smsCodeShow = true
                    this.flowSendSMS.load = false
                    this.flowSendSMS.btnResendSMS = false

                    this.flowSendSMS.countDownResendCode = 15
                    this.countDownFlowSend()
                }, 300);

                setTimeout(() => {
                    document.getElementById('smsCodeForm').focus()
                }, 400);
            })
            .catch(error => {
                setTimeout(() => {
                    this.errors.flow = error.response.status
                }, 500);

                setTimeout(() => {
                    this.errors.flow = false
                    this.flowSendSMS.load = false
                }, 5000);
            })
        },

        countDownFlowSend() {
            if(this.flowSendSMS.countDownResendCode > 0) {
                setTimeout(() => {
                    this.flowSendSMS.countDownResendCode -= 1
                    this.countDownFlowSend()
                }, 1000)
            } else{
                this.flowSendSMS.btnResendSMS = true
            }
        },

        async validateCodeSMS(){
            this.flowSendSMS.load = true
            
            await apiMarket.post('/api/v1/platform/security/phone/validate', this.codeSMS)
            .then(response => {
                console.log(response)
                console.clear()

                setTimeout(() => {
                    this.flowSendSMS.success = true
                    this.flowSendSMS.load = false
                    this.errors.invalidCode = false

                    this.$store.commit('validatePhoneNumber', true)
                }, 1000);
            })
            .catch(error => {
                if ( error.response.status === 400 ){
                    if ( error.response.data === "phone_number_invalid_code" ){
                        this.errors.invalidCode = true
                        this.flowSendSMS.load = false
                    } else{
                        this.errors.flow = error.response.status
                    }
                }
                
                this.flowSendSMS.load = false

                setTimeout(() => {
                    this.errors.flow = false
                }, 5000);
            })
        },



        // VALIDATE REGEX
        regexEmail(emailCheck){
            const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

            if ( emailCheck === "company"){
                if (regex.test(this.validateEmail.company.val)) {
                    this.validateEmail.company.regex = true
                    this.dataMerchant.email = this.validateEmail.company.val
                } else{
                    this.validateEmail.company.regex = false
                    console.log("error")
                }
            } else if ( emailCheck === "partner"){
                if (regex.test(this.validateEmail.partner.val)) {
                    this.validateEmail.partner.regex = true
                    this.modalPartner.partner.email = this.validateEmail.partner.val
                } else{
                    this.validateEmail.partner.regex = false
                }
            }
        },

        regexPhone(phoneCheck){
            const regex = /^\+[1-9]\d{8,14}$/;

            if ( phoneCheck === "company"){
                // let phoneE164Company = this.selectedCountryDDICompany.ddi + this.validatePhone.company.val
                let phoneE164Company = this.validatePhone.company.val
                phoneE164Company = "+" + phoneE164Company.replace(/[^0-9]/g,'')
                
                if (regex.test(phoneE164Company)) {
                    this.validatePhone.company.regex = true
                    this.dataMerchant.phone_number = this.validatePhone.company.val
                } else{
                    this.validatePhone.company.regex = false
                }

            }
            
            if ( phoneCheck === "partner"){
                // let phoneE164Seller = this.selectedCountryDDIPhoneSeller.ddi + this.validatePhone.partner.val
                let phoneE164Seller = this.validatePhone.partner.val
                phoneE164Seller = "+" + phoneE164Seller.replace(/[^0-9]/g,'')
                
                if (regex.test(phoneE164Seller)) {
                    this.validatePhone.partner.regex = true
                    this.modalPartner.partner.phone_number = this.validatePhone.partner.val
                } else{
                    this.validatePhone.partner.regex = false
                }

            }
        },

        checkEmail(emailCheck){
            if ( emailCheck === "company" ){
                if ( this.validateEmail.company.val === this.dataMerchant.companyProfile.email ){
                    this.validateEmail.company.status = true
                } else{
                    this.validateEmail.company.status = false
                }
            } else if ( emailCheck === "person" ){
                if ( this.validateEmail.partner.val === this.dataMerchant.email ){
                    this.validateEmail.partner.status = true
                } else{
                    this.validateEmail.partner.status = false
                }
            }
        },



        // CURRENCIES
        dropListCurrencies(status,place){
            if ( place == "company" ){
                setTimeout(() => {
                    this.listCurrenciesCompanyStatus = status

                    if ( status ){
                        document.getElementById('currencyIDcompany').focus()
                    }
                }, 250)
            }
            
            if ( place == "merchant" ){
                setTimeout(() => {
                    this.listCurrenciesMerchantStatus = status

                    if ( status ){
                        document.getElementById('currencyIDmerchant').focus()
                    }
                }, 250)
            }
        },

        filterListCurrency(place) {
            var input, filter, ul, li, a, i, txtValue;
            input = document.getElementById(`currencyID${place}`);
            filter = input.value.toUpperCase();
            ul = document.getElementById(`drop-list-${place}`);
            li = ul.getElementsByTagName("li");
            
            for (i = 0; i < li.length; i++) {
                a = li[i].getElementsByTagName("a")[0];
                txtValue = a.textContent || a.innerText;
                if (txtValue.toUpperCase().indexOf(filter) > -1) {
                        li[i].style.display = "";
                } else {
                        li[i].style.display = "none";
                }
            }
        },



        // LIST COUNTRY
        dropListCountry(status,place){
            if ( place == "phoneCompany" ){
                setTimeout(() => {
                    this.listPhoneCountryCompanyStatus = status
                }, 250)
                
                if ( status ){
                    setTimeout(() => {
                        this.dropListOpened.countryPhoneCompany = true
                        document.getElementById('phoneNumberCompany').focus()
                    }, 10);
                } else {
                    this.dropListOpened.countryPhoneCompany = false
                }
            }

            if ( place == "company" ){
                setTimeout(() => {
                    this.listCountryCompanyStatus = status
                }, 250)
                
                if ( status ){
                    setTimeout(() => {
                        this.dropListOpened.countryCompany = true
                        document.getElementById('residenceCountryCompany').focus()
                    }, 10);
                } else {
                    this.dropListOpened.countryCompany = false
                }
            }

            if ( place == "seller" ){
                setTimeout(() => {
                    this.listCountrySellerStatus = status
                }, 250)
                
                if ( status ){
                    setTimeout(() => {
                        this.dropListOpened.countrySeller = true
                        document.getElementById('residenceCountrySeller').focus()
                    }, 10);
                } else {
                    this.dropListOpened.countrySeller = false
                }
            }
            
            if ( place == "phonePartner" ){
                setTimeout(() => {
                    this.listCountryPhoneSellerStatus = status
                }, 250)
                
                if ( status ){
                    setTimeout(() => {
                        this.dropListOpened.countryPhoneSeller = true
                        document.getElementById('residenceCountryPhoneSeller').focus()
                    }, 10);
                } else {
                    this.dropListOpened.countryPhoneSeller = false
                }
            }
            
            if ( place == "nationality" ){
                setTimeout(() => {
                    this.listCountryNationalityStatus = status
                }, 250)
                
                if ( status ){
                    setTimeout(() => {
                        this.dropListOpened.countryNacionality = true
                    }, 10);
                } else {
                    this.dropListOpened.countrySeller = false
                }
            }

            if ( place == "contact" ){
                setTimeout(() => {
                    this.listCountryContactStatus = status
                }, 250)
                
                if ( status ){
                    setTimeout(() => {
                        this.dropListOpened.countryContact = true
                        document.getElementById('residenceCountryContact').focus()
                    }, 10);
                } else {
                    this.dropListOpened.countryContact = false
                }
            }

            if ( place == "add" ){
                setTimeout(() => {
                    this.listCountryAddStatus = status
                }, 250)
                
                if ( status ){
                    setTimeout(() => {
                        this.dropListOpened.countryAdd = true
                        document.getElementById('residenceCountryAdd').focus()
                    }, 10);
                } else {
                    this.dropListOpened.countryAdd = false
                }
            }

            // if ( place == "dock" ){
            //     setTimeout(() => {
            //         this.listCountryDockStatus = status
            //     }, 250)
                
            //     if ( status ){
            //         setTimeout(() => {
            //             this.dropListOpened.countryDock = true
            //             document.getElementById('residenceCountryDock').focus()
            //         }, 10);
            //     } else {
            //         this.dropListOpened.countryDock = false
            //     }
            // }
        },

        filterListCountry(place) {
            var input, filter, ul, li, a, i, txtValue;
            input = document.getElementById(`${place}ResidenceCountry`);
            filter = input.value.toUpperCase();
            ul = document.getElementById(`drop-list-${place}`);
            li = ul.getElementsByTagName("li");

            for (i = 0; i < li.length; i++) {
                a = li[i].getElementsByTagName("a")[0];
                txtValue = a.textContent || a.innerText;
                if (txtValue.toUpperCase().indexOf(filter) > -1) {
                        li[i].style.display = "";
                } else {
                        li[i].style.display = "none";
                }
            }
        },


        filterListCountryCompany() {
            var input, filter, ul, li, a, i, txtValue;
            input = document.getElementById("residenceCountryCompany");
            filter = input.value.toUpperCase();
            ul = document.getElementById("drop-list");
            li = ul.getElementsByTagName("li");

            for (i = 0; i < li.length; i++) {
                a = li[i].getElementsByTagName("a")[0];
                txtValue = a.textContent || a.innerText;
                if (txtValue.toUpperCase().indexOf(filter) > -1) {
                        li[i].style.display = "";
                } else {
                        li[i].style.display = "none";
                }
            }
        },

        filterListCountrySeller() {
            var input, filter, ul, li, a, i, txtValue;
            input = document.getElementById("residenceCountrySeller");
            filter = input.value.toUpperCase();
            ul = document.getElementById("drop-list");
            li = ul.getElementsByTagName("li");

            for (i = 0; i < li.length; i++) {
                a = li[i].getElementsByTagName("a")[0];
                txtValue = a.textContent || a.innerText;
                if (txtValue.toUpperCase().indexOf(filter) > -1) {
                        li[i].style.display = "";
                } else {
                        li[i].style.display = "none";
                }
            }
        },
        
        filterListCountryPhoneSeller() {
            var input, filter, ul, li, a, i, txtValue;
            input = document.getElementById("residenceCountryPhoneSeller");
            filter = input.value.toUpperCase();
            ul = document.getElementById("drop-list");
            li = ul.getElementsByTagName("li");

            for (i = 0; i < li.length; i++) {
                a = li[i].getElementsByTagName("a")[0];
                txtValue = a.textContent || a.innerText;
                if (txtValue.toUpperCase().indexOf(filter) > -1) {
                        li[i].style.display = "";
                } else {
                        li[i].style.display = "none";
                }
            }
        },
        
        filterListCountryNationality() {
            var input, filter, ul, li, a, i, txtValue;
            input = document.getElementById("residenceNationalityCountry");
            filter = input.value.toUpperCase();
            ul = document.getElementById("drop-list-nationality");
            li = ul.getElementsByTagName("li");

            for (i = 0; i < li.length; i++) {
                a = li[i].getElementsByTagName("a")[0];
                txtValue = a.textContent || a.innerText;
                if (txtValue.toUpperCase().indexOf(filter) > -1) {
                        li[i].style.display = "";
                } else {
                        li[i].style.display = "none";
                }
            }
        },

        filterListCountryAdd() {
            var input, filter, ul, li, a, i, txtValue;
            input = document.getElementById("residenceCountryAdd");
            filter = input.value.toUpperCase();
            ul = document.getElementById("drop-list");
            li = ul.getElementsByTagName("li");

            for (i = 0; i < li.length; i++) {
                a = li[i].getElementsByTagName("a")[0];
                txtValue = a.textContent || a.innerText;
                if (txtValue.toUpperCase().indexOf(filter) > -1) {
                        li[i].style.display = "";
                } else {
                        li[i].style.display = "none";
                }
            }
        },

        filterListCountryContact() {
            var input, filter, ul, li, a, i, txtValue;
            input = document.getElementById("residenceContactCountry");
            filter = input.value.toUpperCase();
            ul = document.getElementById("drop-list");
            li = ul.getElementsByTagName("li");

            for (i = 0; i < li.length; i++) {
                a = li[i].getElementsByTagName("a")[0];
                txtValue = a.textContent || a.innerText;
                if (txtValue.toUpperCase().indexOf(filter) > -1) {
                        li[i].style.display = "";
                } else {
                        li[i].style.display = "none";
                }
            }
        },

        filterListCountryDock() {
            var input, filter, ul, li, a, i, txtValue;
            input = document.getElementById("residenceDockCountry");
            filter = input.value.toUpperCase();
            ul = document.getElementById("drop-list");
            li = ul.getElementsByTagName("li");

            for (i = 0; i < li.length; i++) {
                a = li[i].getElementsByTagName("a")[0];
                txtValue = a.textContent || a.innerText;
                if (txtValue.toUpperCase().indexOf(filter) > -1) {
                        li[i].style.display = "";
                } else {
                        li[i].style.display = "none";
                }
            }
        },


        // MASK PHONE
        changeMask(maskCheck){
            if ( maskCheck == "phoneCompany" ){
                this.selectedCountryDDICompany.changeMask = false

                setTimeout(() => {
                    this.selectedCountryDDICompany.changeMask = true
                }, 1000);

                setTimeout(() => {
                    document.getElementById('phoneNumberCompany').focus()
                }, 1050);
            }
            
            // if ( maskCheck == "seller" ){
            //     this.selectedCountryDDIPhoneSeller.changeMask = false

            //     setTimeout(() => {
            //         this.selectedCountryDDIPhoneSeller.changeMask = true
            //     }, 1000);

            //     setTimeout(() => {
            //         document.getElementById('phoneNumberSeller').focus()
            //     }, 1050);
            // }
            
            if ( maskCheck == "phonePartner" ){
                this.selectedCountryDDIPhoneSeller.changeMask = false

                setTimeout(() => {
                    this.selectedCountryDDIPhoneSeller.changeMask = true
                }, 1000);

                setTimeout(() => {
                    document.getElementById('phoneNumberSeller').focus()
                }, 1050);
            }
            
            if ( maskCheck == "contact" ){
                this.selectedCountryDDIContact.changeMask = false

                setTimeout(() => {
                    this.selectedCountryDDIContact.changeMask = true
                }, 1000);

                setTimeout(() => {
                    document.getElementById('phoneNumberContact01').focus()
                }, 1050);
            }

            if ( maskCheck == "dock" ){
                this.selectedCountryDDIDock.changeMask = false

                setTimeout(() => {
                    this.selectedCountryDDIDock.changeMask = true
                }, 1000);

                setTimeout(() => {
                    document.getElementById('phoneNumberDock').focus()
                }, 1050);
            }
        },
        


        // LANGUAGES
        dropListLanguages(status, place){
            if ( place == "company" ){
                setTimeout(() => {
                    this.listLanguagesCompanyStatus = status
                }, 250)
    
                if ( status ){
                    setTimeout(() => {
                        this.dropListOpened.languageCompany = true
                        document.getElementById('languageIDcompany').focus()
                    }, 10);
                } else {
                    this.dropListOpened.languageCompany = false
                }
            }
            
            if ( place == "seller" ){
                setTimeout(() => {
                    this.listLanguagesSellerStatus = status
                }, 250)
    
                if ( status ){
                    setTimeout(() => {
                        this.dropListOpened.languageSeller = true
                        document.getElementById('languageIDmerchant').focus()
                    }, 10);
                } else {
                    this.dropListOpened.languageSeller = false
                }
            }
        },

        filterListLanguage(place) {
            var input, filter, ul, li, a, i, txtValue;
            input = document.getElementById(`languageID${place}`);
            filter = input.value.toUpperCase();
            ul = document.getElementById(`drop-list-${place}`);
            li = ul.getElementsByTagName("li");
            
            for (i = 0; i < li.length; i++) {
                a = li[i].getElementsByTagName("a")[0];
                txtValue = a.textContent || a.innerText;
                if (txtValue.toUpperCase().indexOf(filter) > -1) {
                        li[i].style.display = "";
                } else {
                        li[i].style.display = "none";
                }
            }
        },

        changeFlagLanguage(value){
            this.showFlag = false
            this.dataMerchant.languageId = value

            this.showFlag = true
        },
    }
}