const $ = require('jquery')
window.$ = $
    
import getMyContentLanguage from "@/services/contentLanguage.js";
import langsList from "@/services/listLanguages.js";
import countriesList from "@/services/listCountries.js";

// import apiMarket from '@/config/apiMarket.js'
import apiMerchant from '@/config/apiMerchant.js'
import apiPlatform from '@/config/apiPlatform.js'
// import apiPay from '@/config/apiPay.js'

import bus from '@/eventBus';
import moment from 'moment';

import { handleError } from '@/services/errorHandler.js'

export default {
    data(){
        return{
            load: true,

            headerTemplate: "merchant",
            breadcrumb: [],

            sectionManage: {
                load: false,
                btn: "layout",

                status: {
                    layout: true,
                    company: false
                }
            },

            staff: {
                list: [],
                user: {},
                view: {},

                modal: {
                    new: false,
                    view: false,
                    remove: false
                },

                flowStaff: {
                    status: "",
                    load: false
                },

                remove: {
                    confirm: false
                }
            },

            dataCurrency: [],
            dataCountry: [],
            dataLanguage: [],
            dataMerchantTypes: [],
            dataPersonGender: [],
            dataThemes: [],
            dataHeaderStore: [],


            // ERRORS API
            errorsGetGeneral: {
                dataCurrency: false,
                dataCountry: false,
                dataLanguage: false,
                dataMerchantTypes: false,
                dataPersonGender: false,
                dataThemes: false,
                dataHeaderStore: false,
                dataMerchant: false,
            },

            errorsPostGeneral: {
                dataMerchant: false,
                country_pep: false,
                general: false
            },

            // flow: {
            //     typeCode: "account_type_person",
            // },


            newAddress: {
                countryId: '',
                phoneCountryId: '',
                phoneNumber: '',
                // ...
            },

            
            flagSelected: "en",
            showFlag: false,
            showFlagCompany: false,

            listCurrenciesCompanyStatus: false,
            
            listCurrenciesMerchantStatus: false,

            listLanguagesStatus: false,
            selectedLanguage: {},
            selectedLanguageFlag: false,
            
            listLanguagesCompanyStatus: false,
            selectedLanguageCompany: {},
            selectedLanguageCompanyFlag: false,
            
            listLanguagesSellerStatus: false,
            selectedLanguageSeller: {},
            selectedLanguageSellerFlag: false,
            
            listLanguagesContactStatus: false,
            selectedLanguageContact: {},
            selectedLanguageContactFlag: false,
            
            listLanguagesDockStatus: false,
            selectedLanguageDock: {},
            selectedLanguageDockFlag: false,
            
            listCountryCompanyStatus: false,
            selectedCountryCompany: {},
            selectedCountryFlagCompany: false,
            
            listCountrySellerStatus: false,
            selectedCountrySeller: {},
            selectedCountryFlagSeller: false,
            
            listCountryNationalityStatus: false,
            selectedCountryNationality: {},
            selectedCountryFlagNationality: false,
            
            listCountryAddStatus: false,
            selectedCountryAdd: {},
            selectedCountryFlagAddAddress: false,
            
            listCountryStaff: false,
            selectedCountryStaff: {},
            selectedCountryFlagStaff: false,

            // listCountryContactStatus: false,
            // selectedCountryContact: {},
            // selectedCountryFlagAddAddress: false,
            
            // listCountryDockStatus: false,
            // selectedCountryDock: {},
            // selectedCountryFlagDock: false,
            
            selectedCountryDDICompany: {
                changeMask: true,
                phoneMask: '?',
                countrySelected: false,
                flag: ''
            },
            
            selectedCountryDDISeller: {
                changeMask: true,
                phoneMask: '?',
                countrySelected: false,
                flag: ''
            },
            
            selectedCountryDDIContact: {
                changeMask: true,
                phoneMask: '?',
                countrySelected: false,
                flag: ''
            },
            
            selectedCountryDDIDock: {
                changeMask: true,
                phoneMask: '?',
                countrySelected: false,
                flag: ''
            },
            
            selectedCountryDDIStaff: {
                changeMask: true,
                PhoneMask: '?',
                countrySelected: false,
                flag: ''
            },

            dropListOpened: {
                currencyCompany: false,
                languageCompany: false,
                countryCompany: false,
                ddiCompany: false,
                
                currencySeller: false,
                languageSeller: false,
                countrySeller: false,
                ddiSeller: false,

                staff: false,
            },


            accept: {
                terms: false,
                notify: false,
                gdpr: false
            },

            resendLoad: false,
        }
    }, 

    async mounted(){
        
        // MERCHANT TYPES
        await apiMerchant.get('/api/v1/market/store/merchant/types')
        .then(response => {
            this.dataMerchantTypes = response.data
        })
        .catch(error => {
            this.errorsGetGeneral.dataMerchantTypes = error.response.data
        })
        
        
        // PERSON GENDER
        await apiPlatform.get('/api/v1/public/attributes/codes/person_gender')
        .then(response => {
            this.dataPersonGender = response.data
        })
        .catch(error => {
            this.errorsGetGeneral.dataPersonGender = error.response.data
        })
        
        
        // THEMES
        await apiMerchant.get('/api/v1/layout/template/themes')
        .then(response => {
            this.dataThemes = response.data
        })
        .catch(error => {
            this.errorsGetGeneral.dataThemes = error.response.data
        })
        

        // HEADERSTORE
        await apiMerchant.get('/api/v1/layout/template/banners')
        .then(response => {
            this.dataHeaderStore = response.data.filter(hs => hs.banner.attachment.type_code == "market_brand_banner")
            // this.headerStore = this.dataHeaderStore.filter(hs => hs.banner.attachment.type_code == "market_brand_banner")
            // console.log(this.headerStore)
        })
        .catch(error => {
            this.errorsGetGeneral.dataHeaderStore = error.response.data
        })     
        
        this.breadcrumb = [
            {
                slug: `${this.flagSelected}/merchant/dashboard`,
                name: this.getMyLanguage("Merchant","breadcrumb.dashboard")
            },
            {
                name: this.getMyLanguage("Merchant","breadcrumb.manage-store")
            }
        ]


        // STAFF
        this.loadStaff()
    },
    
    methods: {
        getMyLanguage(group, code){
            return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code)
        },

        // LANGUAGES
        languagesList(code){
            return langsList(code, this.$store.state.SelectLanguage.code)
        },

        orderLangs() {
            this.langsTranslated = [...this.$store.state.allLanguages];

            this.langsTranslated.forEach(lang => {
                lang.translatedName = this.languagesList(lang.code);
            });

            this.langsTranslated.sort((a, b) => {
                return a.translatedName.toLowerCase().localeCompare(b.translatedName.toLowerCase());
            });
        },
        
        
        // COUNTRIES
        countryTranslate(code){
            return countriesList(code, this.$store.state.SelectLanguage.code)
        },

        ordercountries() {
            this.countriesTranslated = [...this.$store.state.allCountries];

            this.countriesTranslated.forEach(lang => {
                lang.translatedCountry = this.countryTranslate(lang.IsoCode);
            });

            this.countriesTranslated.sort((a, b) => {
                return a.translatedCountry.toLowerCase().localeCompare(b.translatedCountry.toLowerCase());
            });
        },

        languageSelected(){
            this.flagSelected = this.$store.state.SelectLanguage.code
        },

        goTop(){
            $('html, body').animate({scrollTop : 0},200);
            return false;
        },

        dateTime(value) {
            const format = this.flagSelected === 'en' ? 'YYYY/MM/DD' : 'DD/MM/YYYY';
            return moment(value).format(format);
        },

        // PROFILE
        checkStores(){
            if ( this.dataCountry && this.dataLanguage && this.dataCurrency ) {
                this.loadProfile()
            }
        },

        async loadProfile(){
            if ( !this.dataCountry ){
                this.dataCountry = localStorage.getItem("allCountries")
            }

            await apiMerchant.get('/api/v1/market/store/manage')
            .then(response => {
                this.dataMerchant = response.data
                this.dataMerchant.merchant.dob = moment(this.dataMerchant.merchant.dob).format('YYYY-MM-DD');

                // SLUG
                if ( this.dataMerchant.store.name == this.dataMerchant.store.slug ){
                    this.generateSlugStore(this.dataMerchant.store.name)
                }

                // COUNTRY COMPANY
                if ( this.dataMerchant.company.country_id ){
                    let selectedCC
                    selectedCC = this.dataCountry.filter(cc => cc.Id == this.dataMerchant.company.country_id)

                    this.selectedCountryCompany.name = selectedCC[0].Name
                    this.selectedCountryCompany.ddi = selectedCC[0].DDI
                    this.selectedCountryCompany.phoneMask = selectedCC[0].PhoneMask
                    this.selectedCountryFlagCompany = selectedCC[0].Flag
                }
                
                // LANGUAGE COMPANY
                if ( this.dataMerchant.company.language_id ){
                    let selectedLanguage
                    selectedLanguage = this.dataLanguage.filter(companyLang => companyLang.id == this.dataMerchant.merchant.language_id)

                    this.selectedLanguageCompany.code = selectedLanguage[0].code
                    this.selectedLanguageCompanyFlag = selectedLanguage[0].flag
                }

                // COUNTRY MERCHANT
                if ( this.dataMerchant.merchant.country_id ){
                    let selectedCM
                    selectedCM = this.dataCountry.filter(cc => cc.Id == this.dataMerchant.merchant.country_id )

                    this.selectedCountrySeller.name = selectedCM[0].Name
                    this.selectedCountryFlagSeller = selectedCM[0].Flag
                }
                
                // LANGUAGE MERCHANT
                if ( this.dataMerchant.merchant.language_id ){
                    let selectedLanguage
                    selectedLanguage = this.dataLanguage.filter(merchantLang => merchantLang.id == this.dataMerchant.merchant.language_id )
                    
                    this.selectedLanguageSeller.code = selectedLanguage[0].code
                    this.selectedLanguageSellerFlag = selectedLanguage[0].flag
                }
                
                // COUNTRY NATIONALITY
                if ( this.dataMerchant.merchant.country_id ){
                    let selectedNT
                    selectedNT = this.dataCountry.filter(cc => cc.Id == this.dataMerchant.merchant.country_id )

                    this.selectedCountryNationality.name = selectedNT[0].Name
                    this.selectedCountryFlagNationality = selectedNT[0].Flag
                }
                
                // COUNTRY NEW ADDRESS
                if ( this.newAddress.country_id ){
                    let selectedNA
                    selectedNA = this.dataCountry.filter(cc => cc.id == this.newAddress.country_id )

                    this.selectedCountryContact.name = selectedNA[0].name
                    this.selectedCountryFlagAddAddress = selectedNA[0].flag
                }

                setTimeout(() => {
                    this.load = false
                }, 500);
                
                // console.log(this.dataCountry.filter(cc => cc.id == this.dataMerchant.company.address.countryId)[0].name)
                
                // TEMP
                this.dataMerchant.merchant.gender_id = "3013e802-c8c7-48bf-a1f5-602fff602c7d"
                this.dataMerchant.store.logo_box = false
                this.dataMerchant.store.phoneNumberMaster = ""
                this.dataMerchant.store.phoneNumberSecondary = ""
            })
            .catch(error => {
                setTimeout(() => {
                    this.load = false
                }, 500);
                
                let errorProfile = JSON.stringify(error.response.status)
                this.errorsGetGeneral.dataMerchant = errorProfile

                if ( errorProfile == 401 ){
                    bus.emit('open-login',"401");
                }

                handleError(error, this.flagSelected, this.$store.state.profile.account_type);
            })

            // const urlParams = new URLSearchParams(window.location.search);
            // if ( urlParams.size > 0 ){
            //     localStorage.setItem("referrerID", urlParams.get('utm_content'))
            //     localStorage.setItem("utmSource", urlParams.get('utm_source'))
            //     localStorage.setItem("utmCampaign", urlParams.get('utm_campaign'))
            // }


            const urlParams = new URLSearchParams(window.location.search);
            if ( urlParams.size > 0 ){
                if ( urlParams.get("guide") == "staff" ){
                    setTimeout(() => {
                        this.navSection("staff")
                    }, 600);
                }
                
                if ( urlParams.get("guide") == "layout" ){
                    setTimeout(() => {
                        this.navSection("layout")
                    }, 600);
                }
            }
        },


        // NAVIGATION SECTIONS
        navSection(value){
            if ( value == "layout" ){
                // verifica se há alterações que serão perdidas e gera alerta ....

                this.goTop()
                this.sectionManage.load = true

                setTimeout(() => {
                    this.sectionManage.load = false
                    this.sectionManage.btn = "layout"
                }, 500);
            }
            
            if ( value == "staff" ){
                // verifica se há alterações que serão perdidas e gera alerta ....

                this.goTop()
                this.sectionManage.load = true

                setTimeout(() => {
                    this.sectionManage.load = false
                    this.sectionManage.btn = "staff"
                }, 500);
            }
        },


        // CURRENCIES
        dropListCurrencies(status,place){
            if ( place == "company" ){
                setTimeout(() => {
                    this.listCurrenciesCompanyStatus = status

                    if ( status ){
                        document.getElementById('currencyIDcompany').focus()
                    }
                }, 250)
            }
            
            if ( place == "merchant" ){
                setTimeout(() => {
                    this.listCurrenciesMerchantStatus = status

                    if ( status ){
                        document.getElementById('currencyIDmerchant').focus()
                    }
                }, 250)
            }
        },

        filterListCurrency(place) {
            var input, filter, ul, li, a, i, txtValue;
            input = document.getElementById(`currencyID${place}`);
            filter = input.value.toUpperCase();
            ul = document.getElementById(`drop-list-${place}`);
            li = ul.getElementsByTagName("li");
            
            for (i = 0; i < li.length; i++) {
                a = li[i].getElementsByTagName("a")[0];
                txtValue = a.textContent || a.innerText;
                if (txtValue.toUpperCase().indexOf(filter) > -1) {
                        li[i].style.display = "";
                } else {
                        li[i].style.display = "none";
                }
            }
        },


        formatFileSize(fileSize) {
            if (fileSize === 0) return '0 Bytes';
            const k = 1024;
            const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
            const i = Math.floor(Math.log(fileSize) / Math.log(k));
            return parseFloat((fileSize / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
        },



        // LIST COUNTRY
        dropListCountry(status,place){
            if ( place == "company" ){
                setTimeout(() => {
                    this.listCountryCompanyStatus = status
                }, 250)
                
                if ( status ){
                    setTimeout(() => {
                        this.dropListOpened.countryCompany = true
                        document.getElementById('residenceCountryCompany').focus()
                    }, 10);
                } else {
                    this.dropListOpened.countryCompany = false
                }
            }

            if ( place == "seller" ){
                setTimeout(() => {
                    this.listCountrySellerStatus = status
                }, 250)
                
                if ( status ){
                    setTimeout(() => {
                        this.dropListOpened.countrySeller = true
                        document.getElementById('residenceCountrySeller').focus()
                    }, 10);
                } else {
                    this.dropListOpened.countrySeller = false
                }
            }
            
            if ( place == "nationality" ){
                setTimeout(() => {
                    this.listCountryNationalityStatus = status
                }, 250)
                
                if ( status ){
                    setTimeout(() => {
                        this.dropListOpened.countryNacionality = true
                    }, 10);
                } else {
                    this.dropListOpened.countrySeller = false
                }
            }

            if ( place == "contact" ){
                setTimeout(() => {
                    this.listCountryContactStatus = status
                }, 250)
                
                if ( status ){
                    setTimeout(() => {
                        this.dropListOpened.countryContact = true
                        document.getElementById('residenceCountryContact').focus()
                    }, 10);
                } else {
                    this.dropListOpened.countryContact = false
                }
            }

            if ( place == "add" ){
                setTimeout(() => {
                    this.listCountryAddStatus = status
                }, 250)
                
                if ( status ){
                    setTimeout(() => {
                        this.dropListOpened.countryAdd = true
                        document.getElementById('residenceCountryAdd').focus()
                    }, 10);
                } else {
                    this.dropListOpened.countryAdd = false
                }
            }
            
            if ( place == "staff" ){
                setTimeout(() => {
                    this.listCountryStaff = status
                }, 250)
                
                if ( status ){
                    setTimeout(() => {
                        this.dropListOpened.staff = true
                        document.getElementById('residenceCountryStaff').focus()
                    }, 10);
                } else {
                    this.dropListOpened.staff = false
                }
            }

            // if ( place == "dock" ){
            //     setTimeout(() => {
            //         this.listCountryDockStatus = status
            //     }, 250)
                
            //     if ( status ){
            //         setTimeout(() => {
            //             this.dropListOpened.countryDock = true
            //             document.getElementById('residenceCountryDock').focus()
            //         }, 10);
            //     } else {
            //         this.dropListOpened.countryDock = false
            //     }
            // }
        },

        filterListCountry(place) {
            var input, filter, ul, li, a, i, txtValue;
            input = document.getElementById(`${place}ResidenceCountry`);
            filter = input.value.toUpperCase();
            ul = document.getElementById(`drop-list-${place}`);
            li = ul.getElementsByTagName("li");

            for (i = 0; i < li.length; i++) {
                a = li[i].getElementsByTagName("a")[0];
                txtValue = a.textContent || a.innerText;
                if (txtValue.toUpperCase().indexOf(filter) > -1) {
                        li[i].style.display = "";
                } else {
                        li[i].style.display = "none";
                }
            }
        },


        filterListCountryCompany() {
            var input, filter, ul, li, a, i, txtValue;
            input = document.getElementById("residenceCountryCompany");
            filter = input.value.toUpperCase();
            ul = document.getElementById("drop-list");
            li = ul.getElementsByTagName("li");

            for (i = 0; i < li.length; i++) {
                a = li[i].getElementsByTagName("a")[0];
                txtValue = a.textContent || a.innerText;
                if (txtValue.toUpperCase().indexOf(filter) > -1) {
                        li[i].style.display = "";
                } else {
                        li[i].style.display = "none";
                }
            }
        },

        filterListCountrySeller() {
            var input, filter, ul, li, a, i, txtValue;
            input = document.getElementById("residenceSellerCountry");
            filter = input.value.toUpperCase();
            ul = document.getElementById("drop-list");
            li = ul.getElementsByTagName("li");

            for (i = 0; i < li.length; i++) {
                a = li[i].getElementsByTagName("a")[0];
                txtValue = a.textContent || a.innerText;
                if (txtValue.toUpperCase().indexOf(filter) > -1) {
                        li[i].style.display = "";
                } else {
                        li[i].style.display = "none";
                }
            }
        },
        
        filterListCountryNationality() {
            var input, filter, ul, li, a, i, txtValue;
            input = document.getElementById("residenceNationalityCountry");
            filter = input.value.toUpperCase();
            ul = document.getElementById("drop-list-nationality");
            li = ul.getElementsByTagName("li");

            for (i = 0; i < li.length; i++) {
                a = li[i].getElementsByTagName("a")[0];
                txtValue = a.textContent || a.innerText;
                if (txtValue.toUpperCase().indexOf(filter) > -1) {
                        li[i].style.display = "";
                } else {
                        li[i].style.display = "none";
                }
            }
        },

        filterListCountryAdd() {
            var input, filter, ul, li, a, i, txtValue;
            input = document.getElementById("residenceCountryAdd");
            filter = input.value.toUpperCase();
            ul = document.getElementById("drop-list");
            li = ul.getElementsByTagName("li");

            for (i = 0; i < li.length; i++) {
                a = li[i].getElementsByTagName("a")[0];
                txtValue = a.textContent || a.innerText;
                if (txtValue.toUpperCase().indexOf(filter) > -1) {
                        li[i].style.display = "";
                } else {
                        li[i].style.display = "none";
                }
            }
        },

        filterListCountryContact() {
            var input, filter, ul, li, a, i, txtValue;
            input = document.getElementById("residenceContactCountry");
            filter = input.value.toUpperCase();
            ul = document.getElementById("drop-list");
            li = ul.getElementsByTagName("li");

            for (i = 0; i < li.length; i++) {
                a = li[i].getElementsByTagName("a")[0];
                txtValue = a.textContent || a.innerText;
                if (txtValue.toUpperCase().indexOf(filter) > -1) {
                        li[i].style.display = "";
                } else {
                        li[i].style.display = "none";
                }
            }
        },

        filterListCountryDock() {
            var input, filter, ul, li, a, i, txtValue;
            input = document.getElementById("residenceDockCountry");
            filter = input.value.toUpperCase();
            ul = document.getElementById("drop-list");
            li = ul.getElementsByTagName("li");

            for (i = 0; i < li.length; i++) {
                a = li[i].getElementsByTagName("a")[0];
                txtValue = a.textContent || a.innerText;
                if (txtValue.toUpperCase().indexOf(filter) > -1) {
                        li[i].style.display = "";
                } else {
                        li[i].style.display = "none";
                }
            }
        },

        filterListCountryStaff() {
            var input, filter, ul, li, a, i, txtValue;
            input = document.getElementById("residenceCountryStaff");
            filter = input.value.toUpperCase();
            ul = document.getElementById("drop-list");
            li = ul.getElementsByTagName("li");

            for (i = 0; i < li.length; i++) {
                a = li[i].getElementsByTagName("a")[0];
                txtValue = a.textContent || a.innerText;
                if (txtValue.toUpperCase().indexOf(filter) > -1) {
                        li[i].style.display = "";
                } else {
                        li[i].style.display = "none";
                }
            }
        },


        // MASK PHONE
        changeMask(maskCheck){
            if ( maskCheck == "company" ){
                this.selectedCountryDDICompany.changeMask = false

                setTimeout(() => {
                    this.selectedCountryDDICompany.changeMask = true
                }, 1000);

                setTimeout(() => {
                    document.getElementById('phoneNumberCompany').focus()
                }, 1050);
            }
            
            if ( maskCheck == "seller" ){
                this.selectedCountryDDISeller.changeMask = false

                setTimeout(() => {
                    this.selectedCountryDDISeller.changeMask = true
                }, 1000);

                setTimeout(() => {
                    document.getElementById('phoneNumberSeller').focus()
                }, 1050);
            }
            
            if ( maskCheck == "contact" ){
                this.selectedCountryDDIContact.changeMask = false

                setTimeout(() => {
                    this.selectedCountryDDIContact.changeMask = true
                }, 1000);

                setTimeout(() => {
                    document.getElementById('phoneNumberContact01').focus()
                }, 1050);
            }

            if ( maskCheck == "dock" ){
                this.selectedCountryDDIDock.changeMask = false

                setTimeout(() => {
                    this.selectedCountryDDIDock.changeMask = true
                }, 1000);

                setTimeout(() => {
                    document.getElementById('phoneNumberDock').focus()
                }, 1050);
            }
            
            if ( maskCheck == "staff" ){
                this.selectedCountryDDIStaff.changeMask = false

                setTimeout(() => {
                    this.selectedCountryDDIStaff.changeMask = true
                }, 1000);

                setTimeout(() => {
                    document.getElementById('phoneNumberStaff').focus()
                }, 1050);
            }
        },
        


        // LANGUAGES
        dropListLanguages(status,place){
            if ( place == "company" ){
                setTimeout(() => {
                    this.listLanguagesCompanyStatus = status
                }, 250)
    
                if ( status ){
                    setTimeout(() => {
                        this.dropListOpened.languageCompany = true
                        document.getElementById('languageIDcompany').focus()
                    }, 10);
                } else {
                    this.dropListOpened.languageCompany = false
                }
            }
            
            if ( place == "seller" ){
                setTimeout(() => {
                    this.listLanguagesSellerStatus = status
                }, 250)
    
                if ( status ){
                    setTimeout(() => {
                        this.dropListOpened.languageSeller = true
                        document.getElementById('languageIDmerchant').focus()
                    }, 10);
                } else {
                    this.dropListOpened.languageSeller = false
                }
            }
        },

        filterListLanguage(place) {
            var input, filter, ul, li, a, i, txtValue;
            input = document.getElementById(`languageID${place}`);
            filter = input.value.toUpperCase();
            ul = document.getElementById(`drop-list-${place}`);
            li = ul.getElementsByTagName("li");
            
            for (i = 0; i < li.length; i++) {
                a = li[i].getElementsByTagName("a")[0];
                txtValue = a.textContent || a.innerText;
                if (txtValue.toUpperCase().indexOf(filter) > -1) {
                        li[i].style.display = "";
                } else {
                        li[i].style.display = "none";
                }
            }
        },


        changeFlagLanguage(value){
            this.showFlag = false
            this.dataMerchant.languageId = value

            this.showFlag = true
        },





        // STAFF
        loadStaff(){
            apiMerchant.get(`/api/v2/market/store/merchants`)
            .then(response => {
                this.staff.list = response.data
            })
            .catch(error => {
                console.log(error)
                this.errorsGetGeneral.dataMerchant = JSON.stringify(error.response.status)
                // this.$toast.error("Houve um erro")
            })
        },



        modalAddStaff(){
            this.staff.flowStaff.load = true

            this.staff.modal.new = true
            this.staff.flowStaff.status = "new"

            this.staff.user.full_name = ""
            this.staff.user.email = ""
            this.staff.user.merchant_type_id = ""
            this.staff.user.country_id = ""
            this.staff.user.phone_country_id = ""
            this.staff.user.phone_number = ""
            this.validatePhone.staff.val = ""
            this.staff.user.language_id = this.$store.state.allLanguages.filter(lg => lg.code == localStorage.getItem("codeLanguage"))[0].id
            
            this.validateEmail.staff.regex = ""
            
            this.validatePhone.staff.regex = ""
            this.selectedCountryStaff = {}
            this.selectedCountryFlagStaff = false
            
            this.selectedCountryDDIStaff.changeMask = true,
            this.selectedCountryDDIStaff.PhoneMask = '?',
            this.selectedCountryDDIStaff.countrySelected = false,
            this.selectedCountryDDIStaff.flag = ''
            
            this.errorForm.staff.name = false
            this.errorForm.staff.email = false
            this.errorForm.staff.merchantType = false
            this.errorForm.staff.countryId = false
            this.errorForm.staff.phoneCountryId = false
            this.errorForm.staff.phoneNumber = false
            

            setTimeout(() => {
                this.staff.flowStaff.load = false
            }, 1000);
        },


        modalEdtStaff(value){
            console.log(value)
            this.staff.flowStaff.load = true

            this.staff.user = value

            this.staff.modal.view = false
            this.staff.modal.new = true
            this.staff.flowStaff.status = "edt"

            this.validateEmail.staff.regex = true
            
            this.errorForm.staff.name = false
            this.errorForm.staff.email = false
            this.errorForm.staff.merchantType = false
            this.errorForm.staff.countryId = false
            this.errorForm.staff.phoneCountryId = false
            this.errorForm.staff.phoneNumber = false

            setTimeout(() => {
                this.staff.flowStaff.load = false
            }, 1000);


            // TEMP
            // this.staff.user.full_name = "Julio Ricardo Petters Siqueira"
            // this.staff.user.email = "saintbr@gmail.com"
            // this.staff.user.merchant_type_id = "111"
            // this.staff.user.country_id = "111"
            // this.staff.user.phone_country_id = "111"
            // this.staff.user.phone_number = "99999999999"
        },
        
        modalViewStaff(value){
            this.staff.flowStaff.load = true
            
            console.log(value)

            this.staff.modal.view = true
            this.staff.modal.remove = false

            this.staff.view = value

            setTimeout(() => {
                this.staff.flowStaff.load = false
            }, 1000);
        },

        removeStaff(value){
            if ( value == "start" ){
                this.staff.modal.view = false
                this.staff.modal.remove = true
                this.staff.flowStaff.status = ""
            }

            else if ( value == "confirm" ){
                this.staff.flowStaff.load = true
                this.staff.modal.view = false
                this.staff.modal.remove = true
                this.staff.flowStaff.status = ""
                
                apiMerchant.delete(`/api/v2/market/store/merchant/${this.staff.view.reference}`)
                .then(response => {
                    console.log(response.data)
                    console.clear()

                    this.$toast.success("Utilizador removido com sucesso")

                    this.loadStaff()

                    setTimeout(() => {
                        this.staff.flowStaff.load = false
                        this.staff.flowStaff.status = "removed"
                    }, 500);
                })
                .catch(error => {
                    console.log(error)

                    this.$toast.error("Houve um erro")
                })
            }
        },

        // modalDelStaff(value){
        //     console.log(value)

        //     this.staff.modal.view = false
        //     this.staff.modal.remove = true
        //     this.staff.flowStaff.status = ""
        // },

        // confirmDelStaff(value){
        //     console.log(value)
            
        //     this.staff.flowStaff.load = true
        //     this.staff.flowStaff.status = "removed"
            
        //     setTimeout(() => {
        //         this.staff.flowStaff.load = false
                
        //     }, 1000);
        // },


        saveStaff(value){
            if ( value == "new"){

                apiMerchant.post(`/api/v2/market/store/merchant`, this.staff.user)
                .then(response => {
                    console.log(response.data)
                    console.clear()

                    this.$toast.success("Utilizador adicionado com sucesso!")

                    this.loadStaff()
                    
                    setTimeout(() => {
                        this.staff.flowStaff.load = false
                        this.staff.flowStaff.status = "added"
                    }, 500);
                })
                .catch(error => {
                    console.log(error)

                    this.$toast.error("Houve um erro")
                })
            }
            
            if ( value == "edt"){

                apiMerchant.put(`/api/v2/market/store/merchant/${this.staff.user.reference}`, {"merchant_type_id": this.staff.user.merchant_type_id, "status_id": this.staff.user.status_id})
                .then(response => {
                    console.log(response.data)
                    console.clear()
                    
                    this.$toast.success("Utilizador atualizado com sucesso!")

                    this.loadStaff()
                    
                    setTimeout(() => {
                        this.staff.flowStaff.status === 'edt'
                        this.staff.modal.new = true
                        this.staff.modal.view = false
                        this.staff.modal.remove = false
                        this.staff.flowStaff.load = false
                        this.staff.flowStaff.status = "updated"
                    }, 500);
                })
                .catch(error => {
                    console.log(error)

                    this.$toast.error("Houve um erro")
                })
            }
        }
    }
}