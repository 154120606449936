<template>
    <div class="account">
        <div id="dne"></div>
        <Header :dataSource="headerTemplate" />

        <!-- BREADCRUMB -->
        <Breadcrumb :dataSource="breadcrumb" />

        
        <!-- CONTENT -->
        <section class="content-return">
            <div class="load" v-if="load"></div>

            <div class="row" v-if="!load && errorsGet.dataPurchase != false">
                <div class="col-12">
                    <div class="box box-error">
                        <h3 class="title-secondary">
                            {{getMyLanguage("box-error","title-error")}}
                        </h3>

                        <p v-if='errorsGet.dataPurchase != "401"'>
                            {{getMyLanguage("box-error","msg-error")}}
                        </p>

                        <p v-if='errorsGet.dataPurchase == "401"'>
                            {{getMyLanguage("box-error","401")}}
                        </p>
                    </div>
                </div>
            </div>


            <div class="container" v-if="!load && !errorsGet.dataPurchase">
                <h2 class="title-secondary">
                    {{getMyLanguage("Account","purchases-returns.title")}}
                </h2>

                <!-- <div class="order-select">
                    <p><strong>Seus pedidos</strong></p>
                    <select class="form">
                        <option value="123456789">Nº 123456789 em 16/01/2024 (26.53 DNE Token / 53.06 €)</option>
                        <option value="987654321">Nº 987654321 em 17/01/2024 (80 DNE Token / 160 €)</option>
                        <option value="123654789">Nº 123654789 em 02/02/2024 (120 DNE Token / 240 €)</option>
                    </select>
                </div> -->


                <!-- PURCHASE -->
                <div class="purchase">
                    <div class="header-purchase">
                        <p><strong>#{{dataPurchase.reference}}</strong></p>
                        <p><small>{{dateFormat(dataPurchase.created_at)}} {{timeFormat(dataPurchase.created_at)}} </small></p>
                        <p><span>{{dataPurchase.store.name}}</span></p>
                    </div>

                    <div class="products">
                        <div class="box product"
                            :class="{selected: item.showReason}"
                            v-for="(item, indexItem) in dataPurchase.items"
                            :key="indexItem">

                            <div class="item">
                                <div class="photo">
                                    <img :src='item.product.attachment' :alt="item.product.name">
                                </div>

                                <div class="details">
                                    <h3 class="name">{{item.product.name}}</h3>

                                    <div class="price">
                                        <span>{{item.amount}}</span>
                                        <small>{{(item.price * instrument.price).toLocaleString(instrument.asset_short, {style:"currency", currency:instrument.asset_short})}}</small>
                                    </div>

                                    <div class="btn-return" v-if="!item.return" @click="openModalReason(item)">
                                        {{ getMyLanguage("Account","purchases-returns.btn-return") }}
                                    </div>

                                    <div class="btn-return btn-consult" v-if="item.return" @click="consultReason(item.reference)">
                                        {{ getMyLanguage("Account","purchases-returns.btn-consult") }}
                                    </div>

                                    <!-- <div class="form-group-check" v-if="!item.status_reason">
                                        <input type="checkbox" :id="'item-' + indexReturn" class="form-check" v-model="item.selected" @change="toggleReason(indexReturn)">
                                        <label :for="'item-' + indexReturn" class="form-check-label">Trocar/Devolver</label>
                                    </div> -->
                                </div>
                            </div>
                        </div>

                        <!-- <div class="msg-return">
                            <p class="status pending"><strong>Pendente</strong></p>
                            <p class="option-return pending"><strong>Produto com defeito de fabricação ou sem funcionamento.</strong></p>
                            
                            <div class="messages">
                                <div class="msg client">
                                    <p class="date-time">28/02/2024 às 23:35</p>
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Inventore ex, fugit obcaecati deserunt accusantium culpa.</p>
                                </div>
                                <div class="msg seller">
                                    <p class="date-time">29/02/2024 às 13:32</p>
                                    <p>Voluptate perspiciatis culpa doloremque dolor eveniet totam ad eaque id blanditiis quis! Inventore, saepe repellat!</p>
                                </div>
                            </div>
                        </div> -->
                    </div>


                    <!-- RETURN -->
                    <!-- <div class="return">
                        <label>
                            <strong v-if="!operationReturn">Motivo da devolução</strong>
                            <strong v-ense>Nova mensagem</strong>
                            <span class="characters">{{sendReason.message.length}}/300</span>
                            <textarea class="form" maxlength="300" v-model="sendReason.message"></textarea>
                        </label>

                        <div class="btn-send">
                            <div class="btn-primary" v-if="!operationReturn">Solicitar a devoução</div>
                            <div class="btn-primary" v-else>Enviar</div>
                        </div>
                    </div> -->
                </div>
            </div>
        </section>
    </div>


    <!-- MODAL -->
    <div class="modal modal-return" v-if="modalReason">
        <div class="modal-cont">
            <div class="modal-close" @click="modalReason = false"></div>

            <div class="box">
                <div class="modal-scroll">
                    <div class="reason">
                        <div class="item-reason">
                            <div class="photo">
                                <img :src="sendReason.product.attachment" />
                            </div>

                            <h4 class="name">{{sendReason.product.name}} <small>{{dataPurchase.store.name}}</small></h4>
                        </div>

                        <div class="load" v-if="loadReason"></div>

                        <div class="box-reason" v-if="stepReason == 'form' && !loadReason">
                            <div class="cont">
                                <label>
                                    <strong>{{ getMyLanguage("Account","purchases-returns.modal-return.lbl-qtd") }}</strong>

                                    <select class="form" v-model="sendReason.quantity">
                                        <option v-for="qty in sendReason.quantity" :key="qty" :value="qty">
                                            {{ qty }} {{ qty > 1 ? getMyLanguage("Minicart","resume-cart.items") : getMyLanguage("Minicart","resume-cart.item") }}
                                        </option>
                                    </select>
                                </label>

                                <!-- <div class="actions">
                                    <div class="form-group-radio">
                                        <input type="radio" id="return" name="action" class="form-radio" checked>
                                        <label for="return" class="form-check-label">
                                            Devolução
                                        </label>
                                    </div>
                                    
                                    <div class="form-group-radio">
                                        <input type="radio" id="refund" name="action" class="form-radio">
                                        <label for="refund" class="form-check-label">
                                            Trocar 
                                        </label>
                                    </div>
                                </div> -->
                                <!-- RETURN -->

                                <label>
                                    <strong>{{ getMyLanguage("Account","purchases-returns.modal-return.reason") }}</strong>

                                    <select class="form" v-model="sendReason.name">
                                        <option
                                            v-for="(reason, indexType) in dataReturnReason"
                                            :key="indexType"
                                            :value="reason.name">
                                            
                                            {{ getMyLanguage("Return","return.reason-type." + reason.name) }}
                                        </option>
                                    </select>
                                    
                                    <!-- <select class="form" v-model="sendReason.reason_id">
                                        <optgroup v-for="(group, index) in optionGroups" :label="group.label" :key="index">
                                            <option v-for="(option, optionIndex) in group.options" :value="option.value" :key="optionIndex">
                                            {{ option.label }}
                                            </option>
                                        </optgroup>
                                    </select> -->
                                </label>

                                <label class="message">
                                    <strong>{{ getMyLanguage("Account","purchases-returns.modal-return.comment-reason") }}</strong>
                                    <span class="characters">{{sendReason.message.length}}/200</span>
                                    <textarea class="form" maxlength="200" v-model="sendReason.message"></textarea>
                                </label>
                            </div>

                            <div class="btn-primary" v-if="sendReason.quantity && sendReason.name" @click="confirmation()" >
                                {{ getMyLanguage("Account","purchases-returns.modal-return.btn-continue") }}
                            </div>
                            
                            <div class="btn-primary btn-disabled" v-else>
                                {{ getMyLanguage("Account","purchases-returns.modal-return.btn-continue") }}
                            </div>
                        </div>


                        <div class="box-confirmation" v-if="stepReason == 'confirmation' && !loadReason && !errorsPost.reason">
                            <div class="cont">
                                <p>
                                    <strong>
                                        {{ getMyLanguage("Account","purchases-returns.modal-return.box-confirmation.request") }}&nbsp;
                                    </strong>&nbsp;

                                    {{ getMyLanguage("Account","purchases-returns.modal-return.box-confirmation.return-of") }}&nbsp;
                                    {{ sendReason.quantity }}&nbsp;
                                    {{ sendReason.quantity > 1 ? getMyLanguage("Minicart","resume-cart.items") : getMyLanguage("Minicart","resume-cart.item") }}
                                </p>

                                <p>
                                    <strong>
                                        {{ getMyLanguage("Account","purchases-returns.modal-return.box-confirmation.reason-return") }}&nbsp;
                                    </strong>&nbsp;

                                    {{ getMyLanguage("Return","return.reason-type." + sendReason.name) }}
                                </p>

                                <p v-if="sendReason.message">
                                    <strong>
                                        {{ getMyLanguage("Account","purchases-returns.modal-return.box-confirmation.comment-return") }}&nbsp;
                                    </strong>
                                    
                                    {{sendReason.message}}</p>
                            </div>

                            <div class="obs">
                                {{ getMyLanguage("Account","purchases-returns.modal-return.box-confirmation.obs") }}
                            </div>

                            <div class="bts">
                                <div class="btn-back" @click="stepReason = 'form'"></div>
                                
                                <div class="btn-primary" @click="confirmReason()">
                                    {{ getMyLanguage("Account","purchases-returns.modal-return.box-confirmation.btn-confirm") }}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="box box-error" v-if="errorsPost.reason != false && !loadReason">
                        <h3 class="title-secondary">{{getMyLanguage("box-error","title-error")}}</h3>

                        <p v-if='errorsPost.reason == "market_order_item_already_returned_refunded"'>
                            {{ getMyLanguage("Account","purchases-returns.modal-return.box-confirmation.error.market_order_item_already_returned_refunded") }}
                        </p>

                        <p v-else-if='errorsPost.reason == "market_order_item_exchange_invalid_product"'>
                            {{ getMyLanguage("Account","purchases-returns.modal-return.box-confirmation.error.market_order_item_exchange_invalid_product") }}
                        </p>

                        <p v-else-if='errorsPost.reason == "market_order_item_exchange_invalid_price"'>
                            {{ getMyLanguage("Account","purchases-returns.modal-return.box-confirmation.error.market_order_item_exchange_invalid_price") }}
                        </p>

                        <p v-else>
                            {{getMyLanguage("box-error","msg-error")}}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    // const $ = require('jquery')
    // window.$ = $

    import Header from "@/components/Essentials/Header.vue";
    import Breadcrumb from "@/components/Account/Breadcrumb.vue";

    import getMyContentLanguage from "@/services/contentLanguage.js";

    import apiMarket from '@/config/apiMarket.js'

    import moment from 'moment';

    export default {
        components: {
            Header,
            Breadcrumb
        },

        data() {
            return {
                flagSelected: "en",
                headerTemplate: "account",
                load: true,
                
                breadcrumb: [],
                operationReturn: this.$route.params.idReturn,

                dataPurchase: [],
                dataReturnReason: [],
                dataRefundReason: [],

                instrument: {},
                
                // dataPurchase: [
                //     { name: 'iPhone 14 Pro Max', brand: "Apple", price: {dne: 10, currency: 20}, statusReturn: "", showReason: false, selected: false, selectedOption: "" },
                //     { name: 'Jogo Hogwarts Legacy', brand: "Warner Bros Games", price: {dne: 10, currency: 20}, statusReturn: "", showReason: false, selected: false, selectedOption: "" },
                // ],

                errorsGet: {
                    dataPurchase: false
                },

                
                selectedOption: null,
                optionGroups: [
                    {
                        label: 'Grupo 1',
                        options: [
                            { value: '1', label: 'Opção 1.1' },
                            { value: '2', label: 'Opção 1.2' },
                            { value: '3', label: 'Opção 1.3' }
                        ]
                    },
                    {
                        label: 'Grupo 2',
                        options: [
                            { value: '4', label: 'Opção 2.1' },
                            { value: '5', label: 'Opção 2.2' },
                            { value: '6', label: 'Opção 2.3' }
                        ]
                    }
                    // Adicione mais grupos conforme necessário
                ],


                modalReason: false,

                stepReason: "form",
                loadReason: false,

                sendReason: {},

                errorsPost: {
                    reason: false
                }
            }
        },

        async mounted() {
            this.languageSelected()

            // BREADCRUMB
            const newArray = [
                {
                    slug: `${this.flagSelected}/account/purchases`,
                    name: this.getMyLanguage("Account","purchases.title")
                },
                {
                    slug: null,
                    name: `${this.getMyLanguage("Account","purchases-returns.title")}`
                },
            ];

            this.breadcrumb = newArray;

            
            // INSTRUMENT
            // if ( localStorage.getItem("instrumentTickerShort") ){
            //     this.tickerShort = localStorage.getItem("instrumentTickerShort")
            // } else {
            //     this.tickerShort = "EUR"
            //     localStorage.setItem("instrumentTickerShort","EUR")
            // }


            // ORDER
            await apiMarket.get(`/api/v1/market/order/${this.$route.params.idReturn}`)
            .then(response => {
                this.dataPurchase = response.data

                this.instrument = response.data.instrument
                
                setTimeout(() => {
                    this.load = false
                }, 1000);
            })
            .catch(error => {
                this.errorsGet.dataPurchase = error.response.status

                setTimeout(() => {
                    this.load = false
                }, 1000);

                if ( error.response.status == "401" ){
                    setTimeout(() => {
                        window.location.href = `/${this.flagSelected}/`
                    }, 2000);
                }
            })


            // RETURN TYPES
            const resumeReturnReason = await apiMarket.get('/api/v1/market/order/return/reasons')
            this.dataReturnReason = resumeReturnReason.data
            
            
            // RETURN TYPES
            // const resumeRefundReason = await apiMarket.get('/api/v1/market/order/refund/reason')
            // this.dataRefundReason = resumeRefundReason.data
        },

        methods: {
            languageSelected(){
                this.flagSelected = this.$store.state.SelectLanguage.code
            },

            getMyLanguage(group, code) {
                return getMyContentLanguage(group, code, this.$store.state.SelectLanguage.code);
            },

            dateFormat(value) {
                const format = this.flagSelected === 'br' ? 'pt' : this.flagSelected;
                return moment.utc(value).locale(format).format('DD/MM/YY');
            },

            timeFormat(value) {
                const formattedTime = moment.utc(value).format('HH:mm:ss');
                return formattedTime;
            },

            openModalReason(item){
                this.loadReason = true
                this.modalReason = true
                this.sendReason = item
                this.sendReason.message = ""

                setTimeout(() => {
                    this.loadReason = false
                }, 1500);
            },

            consultReason(ref){
                window.location.href = `/${this.flagSelected}/account/purchases/returns#${ref}`
            },

            toggleReason(index) {
                this.dataPurchase[index].showReason = !this.dataPurchase[index].showReason;
            },

            confirmation(){
                this.loadReason = true
                this.stepReason = 'confirmation'

                setTimeout(() => {
                    this.loadReason = false
                }, 1500);
            },


            confirmReason(){
                this.loadReason = true

                apiMarket.post(`/api/v1/market/order/${this.$route.params.idReturn}/return/${this.sendReason.Id}`, {"reason": this.sendReason.name,"description": this.sendReason.message,"quantity": this.sendReason.quantity,"product_variant_id": null})
                .then(response => {
                    this.dataPurchase = response.data
                    window.location.href = `/${this.flagSelected}/account/purchases/returns`

                    setTimeout(() => {
                        this.loadReason = false
                    }, 1500);
                })
                .catch(error => {
                    console.log(error)
                    this.errorsPost.reason = error.response.data
                    
                    setTimeout(() => {
                        this.loadReason = false
                    }, 1500);
                })
            }
        },

        watch: {
            '$store.state.SelectLanguage.code': {
                immediate: true,
                handler() {
                    this.languageSelected();
                }
            }
        },

        created() {
            const meta = document.querySelector('meta[name="robots"]')
            if (meta) {
                meta.content = 'noindex,nofollow'
                document.title = `${this.getMyLanguage("Account","purchases-returns.title")} | DNE Market`
            } else {
                const newMeta = document.createElement('meta')
                newMeta.name = 'robots'
                newMeta.content = 'noindex,nofollow'
                document.getElementsByTagName('head')[0].appendChild(newMeta)
            }
        },
    };
</script>

<style src="./custom-return.scss" lang="scss" scoped />